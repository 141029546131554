import React, { useState, useEffect, useCallback } from "react";
import {
  SelectField,
  InputField,
  TextareaField,
  ButtonField,
  FileUploadField,
  Iconsvg,
  Tag,
} from "@wk/components-react16";
import axios from "axios";
import { importJournalMembrer, fileUploadApi, userTypeApi } from "../API/API";
import { useDataContext } from "../utils/DataContext";
import * as XLSX from "xlsx";
import { useValidation } from "../Components/Hooks/useValidation";
import { saveAs } from "file-saver";
import { showNotification } from "../Components/NotificatonPopup/showNotification";
import PageTitle from "../Components/PageTitle";

const JrnlMemberUpload = () => {
  PageTitle("Journal Member Upload");
  const { listData = () => {} } = useDataContext() || {};
  let userData = listData?.AuthResponse ? listData?.AuthResponse?.UserName : "";
  let userId = listData?.AuthResponse ? listData?.AuthResponse?.UserId : "";
  const storedEmail = sessionStorage.getItem("Email");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedJournalId, setSelectedJournalId] = useState();
  const [formData, setFormData] = useState({
    JournalId: selectedJournalId,
    SenderName: userData,
    SenderEmail: storedEmail,
    NumberOfMembers: "0",
    SentOn: new Date().toISOString(),
    Remarks: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    JournalId: "",
    SentOn: "",
    SenderName: "",
    SenderEmail: "",
    FileItem: "",
  });
  const [fileItem, setFileItem] = useState(null);
  const [fileData, setFileData] = useState(undefined);
  const [, setShowErrors] = useState(false);
  const [validUserTypes, setValidUserTypes] = useState([]);
  let req = false;
  let file = null;
  const [excelError, setExcelError] = useState(false);
  const listJournal = listData?.Data?.EditorJournalList ?? [];
  const [countValue, setCountValue] = useState(0);
  const [updateValue, setUpdateValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [, setErrorResponse] = useState("");
  const [fileErrorMessages, setfileErrorMessages] = useState({
    FileItem: "",
  });
  //=========validation================================
  const { isValidEmail, handleNumberInput } = useValidation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newErrorMessages = { ...errorMessages };
    if (name === "SenderEmail") {
      if (value.trim() === "") {
        newErrorMessages.SenderEmail = "Email is required";
      } else if (!isValidEmail(value)) {
        newErrorMessages.SenderEmail = "Invalid email address";
      } else {
        newErrorMessages.SenderEmail = "";
      }
    }
    if (["JournalId", "SentOn", "SenderName"].includes(name)) {
      newErrorMessages[name] = "";
    }
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [name]: "",
    }));

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorMessages(newErrorMessages);
  };

  const handleJournalChange = (e) => {
    setSelectedJournalId(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      JournalId: e.target.value,
    }));
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      JournalId: "",
    }));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData((prevData) => ({
      ...prevData,
      SentOn: date.toISOString(),
    }));
  };

  const handleFileChange = (event) => {
    setExcelError(false);
    setCountValue(0);
    setUpdateValue(0);
    const file = event.target.files[0];
    if (file) {
      if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setFileItem({
          id: "file",
          nativeFile: file,
          label: file.name,
          status: "uploading",
        });
        setfileErrorMessages({ FileItem: "" });
        handleExcelRead(file, validUserTypes);
        setTimeout(() => {
          setFileItem((prevFileItem) => ({
            ...prevFileItem,
            status: "completed",
          }));
          event.target.value = "";
        }, 7000);
      } else {
        setFileItem({
          id: "file",
          nativeFile: file,
          label: file.name,
          status: "error",
        });
        setfileErrorMessages({ FileItem: "Please select a valid .xlsx file" });
        return;
      }
    } else {
      setfileErrorMessages({ FileItem: "Please select a file" });
      return;
    }

    let reader = new FileReader();
    reader.onload = function () {};
    reader.readAsDataURL(event.target.files[0]);
  };

  useEffect(() => {
    async function fetchDataAndReadExcel() {
      try {
        const response = await axios.get(userTypeApi);
        const userTypesFromApi = response?.data?.Data?.map(
          (item) => item.Users
        );

        setValidUserTypes(userTypesFromApi);
        if (file) {
          handleExcelRead(file, userTypesFromApi);
        }
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    }

    fetchDataAndReadExcel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExcelRead = useCallback(
    (file, validUserTypes) => {
      if (file) {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const data = new Uint8Array(arrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const parsingOptions = {
            header: 2,
          };
          const parsedData = XLSX.utils.sheet_to_json(
            worksheet,
            parsingOptions
          );
          const rowsWithErrors = [];

          parsedData.forEach((item) => {
            const validationErrors = [];
            if (!item.FirstName) {
              validationErrors.push("First Name should not be empty.");
            }
            if (!item.Address1) {
              validationErrors.push("Address should not be empty.");
            }
            if (!item.UserType) {
              validationErrors.push("UserType should not be empty.");
            } else if (
              !Array.isArray(validUserTypes) ||
              !validUserTypes.includes(item.UserType)
            ) {
              validationErrors.push("UserType is invalid.");
            }
            if (!item.State) {
              validationErrors.push("State should not be empty.");
            }
            if (!isValidBoolean(item.IsActive)) {
              validationErrors.push(
                "IsActive is invalid. The value should be from(TRUE or FALSE)"
              );
            }
            if (!isValidBoolean(item.PrintRequired)) {
              validationErrors.push(
                "PrintRequired is invalid.The value should be from(TRUE or FALSE)"
              );
            }

            if (!isValidNumber(item.Pin)) {
              validationErrors.push(
                "Pin is invalid, the value should be number."
              );
            }

            if (!item.Country) {
              validationErrors.push("Country should not be empty.");
            }

            if (validationErrors.length > 0) {
              setExcelError(true);
              item.ValidationErrors = validationErrors;
              rowsWithErrors.push(item);
            } else {
              setExcelError(false);
              console.log("No errors found. File will not be saved.");
            }
          });

          const convertToString = (value, defaultValue = "") => {
            return value?.toString() || defaultValue;
          };

          const convertToBooleanString = (value, defaultValue = false) => {
            return (value ?? defaultValue).toString();
          };

          const formattedData = parsedData.map((item) => ({
            ...item,
            Id: item.Id || 0,
            MemberShipId: convertToString(item.MemberShipId),
            FirstName: convertToString(item.FirstName),
            Email: convertToString(item.Email),
            Mobile: convertToString(item.Mobile),
            UserType: convertToString(item.UserType),
            Address1: convertToString(item.Address1),
            Address2: convertToString(item.Address2),
            Address3: convertToString(item.Address3),
            City: convertToString(item.City),
            State: convertToString(item.State),
            Country: convertToString(item.Country),
            Pin: convertToString(item.Pin),
            PrintRequired: convertToBooleanString(item.PrintRequired),
            IsActive: convertToBooleanString(item.IsActive, true),
            JournalId: +selectedJournalId,
            CreatedBy: userId || "",
          }));

          setFileData(formattedData);
          setLoading(false);
          if (rowsWithErrors.length > 0) {
            setExcelError(true);
            const rearrangedHeaders = Object.keys(parsedData[0]).filter(
              (key) => key !== "ValidationErrors"
            );
            rearrangedHeaders.push("ValidationErrors");

            const updatedSheetData = rowsWithErrors.map((item) => {
              const updatedItem = {};
              rearrangedHeaders.forEach((header) => {
                updatedItem[header] =
                  header === "ValidationErrors"
                    ? item.ValidationErrors.join("\n")
                    : item[header];
              });
              return updatedItem;
            });
            const updatedWorksheet = XLSX.utils.json_to_sheet(
              updatedSheetData,
              {
                header: rearrangedHeaders,
              }
            );
            const newWorkbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              newWorkbook,
              updatedWorksheet,
              "UpdatedSheet"
            );

            const updatedWorkbook = XLSX.write(newWorkbook, {
              bookType: "xlsx",
              type: "binary",
            });
            const blob = new Blob([s2ab(updatedWorkbook)], {
              type: "application/octet-stream",
            });
            saveAs(blob, "error_file.xlsx");
          } else {
            console.log("No errors found. File will not be saved.");
          }
        };
        fileReader.readAsArrayBuffer(file);
      }
    },
    [setFileData, setLoading, setExcelError, selectedJournalId, userId]
  );

  useEffect(() => {
    async function fetchDataAndReadExcel() {
      try {
        const response = await axios.get(userTypeApi);
        const userTypesFromApi = response?.data?.Data?.map(
          (item) => item.Users
        );

        setValidUserTypes(userTypesFromApi);
        if (file) {
          await handleExcelRead(file, userTypesFromApi);
        }
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    }

    fetchDataAndReadExcel();
  }, [file, handleExcelRead]);

  function isValidBoolean(value) {
    return (
      value === 1 ||
      value === 0 ||
      value === "1" ||
      value === "0" ||
      value === true ||
      value === false ||
      value === "true" ||
      value === "false" ||
      value === "TRUE" ||
      value === "FALSE"
    );
  }
  function isValidNumber(value) {
    if (value === "") {
      value = 0;
    }

    return Number.isInteger(value);
  }

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ["JournalId", "SentOn", "SenderName", "SenderEmail"];
    const newErrorMessages = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrorMessages[field] = `The ${field} field is required.`;
      }
    });

    if (!fileItem?.nativeFile) {
      fileErrorMessages.FileItem = "Please select a file.";
    }

    setfileErrorMessages(fileErrorMessages);
    setErrorMessages(newErrorMessages);

    if (
      Object.keys(newErrorMessages).length > 0 ||
      fileErrorMessages.FileItem ||
      excelError
    ) {
      setShowErrors(true);
      return;
    }
    setLoading(true);
    try {
      const importJournalResponse = await axios.post(
        importJournalMembrer,
        {
          JournalId: formData.JournalId,
          SenderName: formData.SenderName,
          SenderEmail: formData.SenderEmail,
          SentOn: formData.SentOn,
          Remarks: formData.Remarks,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (importJournalResponse.data.StatusCode === 201) {
        const journalId = importJournalResponse.data.Data;

        const fileUploadApiResponse = await axios.post(fileUploadApi, {
          JournalMembersUploadFileID: journalId,
          JournalMembersDTO: fileData,
        });
        const countVal = fileUploadApiResponse?.data?.Data?.InsertCount;
        const updateVal = fileUploadApiResponse?.data?.Data?.UpdateCount;
        const errorMembers = fileUploadApiResponse?.data?.Data?.errrorMembers;
        setCountValue(countVal);
        setUpdateValue(updateVal);
        setErrorResponse(errorMembers);
        if (errorMembers && errorMembers.length > 0) {
          setLoading(false);
          downloadErrorMembersExcel(errorMembers);
          if (countVal && updateVal) {
            showNotification(
              "warning",
              "Only " +
                countVal +
                " member added. And " +
                updateVal +
                " member updated. Please check error_members file for remaining members!"
            );
          } else if (countVal) {
            showNotification(
              "warning",
              "Only " +
                countVal +
                " member added successfully. Please check error_members file for remaining members!"
            );
          } else if (updateVal) {
            showNotification(
              "warning",
              "Only " +
                updateVal +
                " member updated successfully. Please check error_members file for remaining members!"
            );
          } else {
            showNotification(
              "warning",
              "Unknown case. Please check the API response."
            );
          }
        } else if (countVal && updateVal) {
          showNotification(
            "success",
            updateVal +
              " Member added and " +
              updateVal +
              " updated successfully."
          );
          setLoading(false);
        } else if (countVal) {
          showNotification("success", "Member added successfully.");
          setLoading(false);
        } else if (updateVal) {
          showNotification("success", "Member updated successfully.");
          setLoading(false);
        } else {
          showNotification("error", "Error while adding member");
          setLoading(false);
        }
      }
    } catch (errors) {
      setLoading(false);
      if (errors.response && errors.response.status === 400) {
        const errrorMembers = errors?.response?.data?.Data?.errrorMembers;
        console.log(errrorMembers);
        if (Array.isArray(errrorMembers) && errrorMembers.length > 0) {
          showNotification(
            "error",
            "Error while adding member. Please check auto-downloaded error_members file."
          );
          downloadErrorMembersExcel(errrorMembers);
        } else {
          showNotification("error", "Error while adding member.");
          console.error(
            "ErrorMembers data is not in the expected format:",
            errrorMembers
          );
        }
      }
      console.error(errors);
    } finally {
      const defaultFormData = {
        JournalId: 0,
        SentOn: new Date().toISOString().split("T")[0],
        SenderName: userData,
        SenderEmail: storedEmail,
        Remarks: "",
      };
      setFormData(defaultFormData);
      setFileItem(null);
    }
  };

  const downloadErrorMembersExcel = (errorMembers) => {
    // Move ErrorDetails column to the end
    errorMembers = errorMembers.map((member) => {
      const { ErrorDetails, ...rest } = member;
      return { ...rest, ErrorDetails };
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(errorMembers);
    XLSX.utils.book_append_sheet(workbook, worksheet, "error_members");
    const excelArrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelBlob = new Blob([excelArrayBuffer], {
      type: "application/octet-stream",
    });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(excelBlob);
    downloadLink.download = "error_members.xlsx";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  //================sample file download============

  return (
    <div className="wk-page-container wk-page-container-custom">
      {loading ? (
        <div className="wk-is-centered wk-margins">
          <p className="wk-weight-500 wk-text-muted-light">
            Journal Members is being uploaded...
          </p>
          <Iconsvg name="spinner" isSpin></Iconsvg>
        </div>
      ) : (
        <>
          <div className="wk-margins">
            {" "}
            <h1 className="wk-display-5 mb">Import members for the Journal</h1>
            <hr className="wk-divider"></hr>
            <div className="wk-size-5 mb">Senders / File Information</div>
          </div>
          <div className="wk-row wk-row-flex-custom">
            <div className="wk-custom-col wk-col-6-laptop wk-col-6-desktop wk-col-6-hd">
              <form
                onSubmit={handleSubmit}
                className=" wk-box-shadow-overlay wk-form-custom"
              >
                <SelectField
                  label="Select Journal"
                  labelFor="journalId"
                  size="small"
                  indicator="required"
                >
                  <select
                    id="journalId"
                    value={formData.JournalId}
                    onChange={handleJournalChange}
                    name="JournalId"
                    required
                  >
                    <option>Choose an option</option>
                    {listJournal?.map((journal) => (
                      <option key={journal.JournalId} value={journal.JournalId}>
                        {journal.JournalName}
                      </option>
                    ))}
                  </select>
                  {errorMessages.JournalId && (
                    <span className="wk-primary-red">
                      please select valid Journal.
                    </span>
                  )}
                </SelectField>
                <InputField
                  label="Select date"
                  labelFor="Select date"
                  indicator="required"
                  size="small"
                >
                  <input
                    type="date"
                    id="Date"
                    name="Date"
                    placeholder="Search everywhere"
                    value={selectedDate.toISOString().split("T")[0]}
                    onChange={(e) => handleDateChange(new Date(e.target.value))}
                  />
                  {errorMessages.SentOn && (
                    <span className="wk-primary-red">
                      {errorMessages.SentOn}
                    </span>
                  )}
                </InputField>

                <InputField
                  label="Sender's Name"
                  labelFor="senderName"
                  size="small"
                  indicator="required"
                >
                  <input
                    type="text"
                    id="senderName"
                    name="SenderName"
                    value={formData.SenderName}
                    onChange={handleInputChange}
                    onKeyDown={handleNumberInput}
                    //disabled="true"
                    required
                  />
                  {errorMessages.SenderName && (
                    <span className="wk-primary-red">
                      {errorMessages.SenderName}
                    </span>
                  )}
                </InputField>

                <InputField
                  label="Sender's Email ID"
                  labelFor="senderEmail" // Make sure this matches the id below
                  size="small"
                  indicator="required"
                >
                  <input
                    type="text"
                    id="senderEmail" // Make sure this matches the labelFor above
                    name="SenderEmail" // Make sure this matches the key in formData
                    value={formData.SenderEmail}
                    onChange={handleInputChange}
                    required
                  />
                  {errorMessages.SenderEmail && (
                    <span className="wk-primary-red">
                      {errorMessages.SenderEmail}
                    </span>
                  )}
                </InputField>
                <TextareaField label="Remarks" labelFor="remarks" size="small">
                  <textarea
                    id="remarks"
                    name="Remarks"
                    type="text"
                    value={formData.Remarks}
                    onChange={handleInputChange}
                  ></textarea>
                </TextareaField>

                <FileUploadField
                  label="Import file"
                  description="Only .xlsx file."
                  id="fileUpload"
                  name="fileUpload"
                  fileErrorMessages
                  fileItems={fileItem ? [fileItem] : []}
                  labelFor="fileUpload"
                >
                  <div className="file-upload-container">
                    <input
                      type="file"
                      id="fileInput"
                      name="fileUpload"
                      accept=".xlsx"
                      onChange={handleFileChange}
                    />
                    {req && <span className="wk-primary-red">{req}</span>}

                    {fileErrorMessages.FileItem && (
                      <span className="error-message wk-primary-red">
                        {fileErrorMessages.FileItem}
                      </span>
                    )}
                  </div>
                </FileUploadField>

                {excelError && (
                  <p className="custom-error-message wk-primary-red wk-size-2">
                    There is an error in the File. The error file will be
                    downloaded in few seconds.
                  </p>
                )}
                <ButtonField>
                  <button type="button" id="save" onClick={handleSubmit}>
                    Save
                  </button>
                </ButtonField>
              </form>
            </div>
            <br />
            <div className="wk-custom-col wk-col-6-laptop wk-col-6-desktop wk-col-6-hd margin">
              <div className="wk-box-shadow-overlay wk-form-custom ">
                <div className="wk-docs-paragraph-sample wk-size-2 wk-margins">
                  <p className="wk-size-2 mb wk-weight-500">
                    Before importing file please read the below instructions
                  </p>

                  <p>
                    1.<span> Download sample file</span>
                    <a
                      href="/Journal_Members.xlsx"
                      download="Journal_Members.xlsx"
                    >
                      (samplefile.xlsx)
                    </a>
                  </p>
                  <p>
                    2. Insert data as per the columns available in sample file
                  </p>
                  <p>3. Import complementary users excel file</p>
                  <p>
                    4.<span> Download sample file</span>
                    <a
                      href="/JournalMembers_Masterdata.xlsx"
                      download="JournalMembers_Masterdata.xlsx"
                    >
                      (JournalMembers_Masterdata.xlsx)
                    </a>
                  </p>
                </div>
              </div>
              <div className="wk-box-shadow-overlay wk-form-custom MemberCount margin">
                <div className="wk-docs-paragraph-sample">
                  <div className="wk-size-3">
                    <p className="wk-size-2 mb wk-weight-500">
                      {" "}
                      Member upload count
                    </p>
                    <p className="wk-size-2">
                      <span> Total Members Created:</span>{" "}
                      <Tag color="blue"> {countValue}</Tag>
                    </p>
                    <p className="wk-size-2">
                      <span>Total Members Updated:</span>{" "}
                      <Tag color="green"> {updateValue}</Tag>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(JrnlMemberUpload);
