import React from "react";
import NotificatonPopup from ".";
import { displayReactElement } from "../../utils/displayReactElement";

export const showNotification = (
  type,
  heading,
  children = "",
  autoCloseTimeout = 3000 // success message will be hide after 3 seconds
) => {
  const element = (
    <NotificatonPopup
      type={type}
      heading={heading}
      autoCloseTimeout={autoCloseTimeout}
    >
      {children}
    </NotificatonPopup>
  );

  displayReactElement(element, ".js-notification");
};

Object.defineProperty(window, "showNotification", {
  value: showNotification,
});
