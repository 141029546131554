import React from "react";
import PageTitle from "../Components/PageTitle";
import { Accordion } from "@wk/components-react16";
import { AccordionItem } from "@wk/components-react16";
import { AccordionItemHeader } from "@wk/components-react16";
import { AccordionItemBody } from "@wk/components-react16";
import Help1Image from "../image_help/Help-1.png";
import Help2Image from "../image_help/Help-2.png";
import Help3Image from "../image_help/Help-3.png";
import Help4Image from "../image_help/Help-4.png";
import Help5Image from "../image_help/Help-5.png";
import Help6Image from "../image_help/Help-6.png";
import Help7Image from "../image_help/Help-7.png";
import Help8Image from "../image_help/Help-8.png";
import Help9Image from "../image_help/Help-9.png";
import Help10Image from "../image_help/Help-10.png";
import Help11Image from "../image_help/Help-11.png";
import Help12Image from "../image_help/Help-12.png";
import Help13Image from "../image_help/Help-13.png";
import Help14Image from "../image_help/Help-14.png";

const Help = () => {
  PageTitle("Help");
  return (
    <>
      <div className="wk-page-container wk-page-container-custom">
        <div className="wk-grid-stage-workflow-container">
          <div className="wk-grid">
            <div className="wk-column-12">
              <h1>Editorial Console Quick Guide</h1>
              <div>
                <p className="wk-notification-title wk-help-align">
                  Welcome to the Editorial Console, your centralized Editorial
                  hub for managing Member details, Article Type Rates,
                  Manuscript Submission, Processing, and Color Reproduction Fees
                  for Medknow Journals. This guide will help you get started
                  with the platform.
                </p>
              </div>
              <div>
                <div className="wk-help-align">
                  <h3>Key Features:</h3>
                  <ul>
                    <li>
                      User-Friendly Interface: The intuitive design ensures easy
                      navigation, making your tasks more efficient.
                    </li>
                    <li>
                      Member Management: Update member details effortlessly and
                      oversee your journal's count of subscribers.
                    </li>
                    <li>
                      Submission, Processing, Color Reproduction Fees:
                      Streamline and modify fees for manuscripts on submission
                      and acceptance.
                    </li>
                    <li>
                      Article Type Rates: View rates for different article types
                      within your journal.
                    </li>
                  </ul>
                </div>
                <br />

                <Accordion>
                  <AccordionItem isOpen>
                    <h2
                      id="tips"
                      slot="accordionItemHeader"
                      style={{ backgroundColor: "#E3F2FC" }}
                    >
                      <AccordionItemHeader>
                        <h4>Getting Started </h4>
                      </AccordionItemHeader>
                    </h2>
                    <AccordionItemBody slot="accordionItemBody">
                      <ul>
                        <br />
                        <li>
                          Visit the Editorial Console website:
                          <a
                            href="https://editorialconsole.medknow.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            [https://editorialconsole.medknow.com/]
                          </a>
                        </li>
                        <li>
                          Enter your Username and password as shared in the
                          email to login to your account.
                        </li>
                        <br />
                        <img
                          src={Help1Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                      </ul>
                    </AccordionItemBody>
                  </AccordionItem>
                  <AccordionItem>
                    <h2
                      id="examples"
                      slot="accordionItemHeader"
                      style={{ backgroundColor: "#E3F2FC" }}
                    >
                      <AccordionItemHeader>
                        <h4>Member Management</h4>
                      </AccordionItemHeader>
                    </h2>
                    <AccordionItemBody slot="accordionItemBody">
                      <br />
                      <ul>
                        <h5>Updating Member Details</h5>{" "}
                      </ul>{" "}
                      <br />
                      <ul>
                        <li>
                          Select the Journal name from the dropdown list (in
                          case of multiple journals associated with your
                          account, all will appear here)
                        </li>
                        <li>Click on the "Admin" section.</li>
                        <li>
                          Go to <strong>“Journal Member Update”</strong> menu
                          from the dropdown list.
                        </li>
                        <br />
                        <img
                          src={Help2Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                        <li>
                          Search and select the member by name, membership ID,
                          or Email address, whose details need updating.
                        </li>
                        <li>Make the necessary changes and save.</li>
                        <br />
                        <img
                          src={Help3Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                        <li>
                          To add a new member, click on “Add new member” option.
                          Make the necessary changes and save.{" "}
                        </li>
                        <br />
                        <img
                          src={Help4Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                      </ul>
                      <ul>
                        <h5>Importing Member Details</h5>{" "}
                      </ul>
                      <ul>
                        <li>Click on the "Admin" section.</li>
                        <li>
                          Go to “Journal Member Upload” menu from the dropdown
                          list.
                        </li>
                        <img
                          src={Help5Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                        <li>
                          Download the samplefile.xlsx sheet, then import the
                          updated file along with necessary details on the page
                          and save.
                        </li>
                        <img
                          src={Help6Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                      </ul>
                    </AccordionItemBody>
                  </AccordionItem>
                  <AccordionItem>
                    <h2
                      id="guidelines"
                      slot="accordionItemHeader"
                      style={{ backgroundColor: "#E3F2FC" }}
                    >
                      <AccordionItemHeader>
                        <h4>
                          Managing Article Submission, Processing, & Color
                          Reproduction Fees
                        </h4>
                      </AccordionItemHeader>
                    </h2>
                    <AccordionItemBody slot="accordionItemBody">
                      <br />
                      <h5>Entering Custom Rates</h5> <br />
                      <ul className="wk-size-3">
                        <li>
                          Click on the <strong>"Admin"</strong> section.
                        </li>
                        <li>
                          Go to “Custom Manuscript Rate” menu from the dropdown
                          list.
                        </li>

                        <img
                          src={Help7Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                        <li>
                          Select <strong>Journal Name</strong> and{" "}
                          <strong>Article Type</strong> and click on{" "}
                          <strong>‘Select’</strong>.
                        </li>

                        <img
                          src={Help8Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                        <li>
                          Click <strong>“Add Manuscript Rate”</strong> to add a
                          new manuscript ID with a custom fee rate.{" "}
                        </li>
                        <img
                          src={Help9Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                        <li>
                          Enter manuscript ID and amount in the necessary fields
                          in INR or USD currency and save.Please ensure to enter
                          ‘0’ in rest of the fields before saving. (Please refer
                          sample screenshot below).
                        </li>
                      </ul>
                      <ul>
                        <h5>Fields Description:</h5>
                        <strong>MS INR, MS USD:</strong> Submission Fees in
                        Indian Rupees and/or US Dollars
                        <br />
                        <strong>APC INR, APC USD:</strong>Processing and/or
                        Color Reproduction Fees in Indian Rupees and/or US
                        Dollars
                        <br />
                        <img
                          src={Help10Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                        <li>
                          Saved entries will appear on the table with ‘Edit’ and
                          ‘Delete’ actions to remove the entries.{" "}
                        </li>
                        <img
                          src={Help11Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                      </ul>
                      <ul>
                        <h5>Viewing Payment Received Report</h5>

                        <li>Click on the "Admin" section.</li>
                        <li>
                          Go to “Payment Received Report” menu from the dropdown
                          list
                        </li>
                        <img
                          src={Help12Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                        <li>
                          Select the range of payments or manuscript ID and
                          click <strong>‘Select’</strong> to view results. The
                          approved Manuscript payments will appear in this list.{" "}
                        </li>
                        <li>
                          To download in excel sheet, click{" "}
                          <strong>“Export to Excel”.</strong>{" "}
                        </li>
                        <img
                          src={Help13Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                      </ul>
                    </AccordionItemBody>
                  </AccordionItem>
                  <AccordionItem>
                    <h2
                      id="examples"
                      slot="accordionItemHeader"
                      style={{ backgroundColor: "#E3F2FC" }}
                    >
                      <AccordionItemHeader>
                        <h4>Editorial Dashboard Tabs</h4>
                      </AccordionItemHeader>
                    </h2>
                    <AccordionItemBody slot="accordionItemBody">
                      <br />
                      <ul>
                        <li>
                          Navigate to the{" "}
                          <strong>"Count of Subscribers"</strong> tab to view
                          the subscribers subscribed for Online, Institutional,
                          and Individual Issue types.
                        </li>
                        <li>
                          Navigate to the “Custom Manuscript Rates” tab to view
                          the recently added custom fees for manuscripts. To add
                          or remove any rates, please go to “Custom Manuscript
                          rates” section.
                        </li>
                        <li>
                          Navigate to the “Article Rates” tab to view rates for
                          different article types within your journal.{" "}
                        </li>
                        <img
                          src={Help14Image}
                          alt="Wolters Kluwer logo"
                          width={"70%"}
                        />
                        <br />
                        <br />
                        If you have any questions or require assistance, please
                        don't hesitate to contact our dedicated support team at
                        [WKHLRPMedknow_techsupport@wolterskluwer.com]. We are
                        here to provide the help you need.
                      </ul>
                    </AccordionItemBody>
                  </AccordionItem>
                </Accordion>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Help);
