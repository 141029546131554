import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../Components/NotificatonPopup/showNotification";

const SessionTimeoutHandler = ({ children, sessionTimeout }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const sessionTimeoutRef = useRef(null);
  //const SESSION_TIMEOUT = 20 * 60 * 1000; // 20 minutes
  const navigate = useNavigate();

  // Clear session timeout
  const clearSessionTimeout = useCallback(() => {
    if (sessionTimeoutRef.current) {
      clearTimeout(sessionTimeoutRef.current);
      sessionTimeoutRef.current = null;
    }
  }, []);

  // Handle logout process
  const handleLogout = useCallback(() => {
    if (window.isLoggingOut) return;
    window.isLoggingOut = true;
    clearSessionTimeout();
    sessionStorage.clear();
    setIsLoggedIn(false);
    showNotification("info", "You have been logged out due to inactivity.");
    navigate("/");
  }, [navigate, clearSessionTimeout]);

  // Start session timeout
  const startSessionTimeout = useCallback(() => {
    if (!isLoggedIn) return;
    clearSessionTimeout(); // Clear any existing timeout
    sessionTimeoutRef.current = setTimeout(() => {
      handleLogout();
    }, sessionTimeout);
  }, [isLoggedIn, handleLogout, clearSessionTimeout, sessionTimeout]);

  // Handle user activity to reset timeout
  const handleUserActivity = useCallback(() => {
    if (isLoggedIn) {
      startSessionTimeout();
    }
  }, [isLoggedIn, startSessionTimeout]);

  useEffect(() => {
    // Check if the user is logged in
    const accessToken = sessionStorage.getItem("access_token");
    if (accessToken) {
      setIsLoggedIn(true);
      startSessionTimeout();
    }

    // Attach event listeners for user activity
    const events = ["mousemove", "keypress", "click", "scroll"];
    if (isLoggedIn) {
      events.forEach((event) => {
        window.addEventListener(event, handleUserActivity);
      });
    }

    // Cleanup on component unmount
    return () => {
      if (isLoggedIn) {
        events.forEach((event) => {
          window.removeEventListener(event, handleUserActivity);
        });
        clearSessionTimeout();
      }
    };
  }, [
    isLoggedIn,
    handleUserActivity,
    startSessionTimeout,
    clearSessionTimeout,
  ]);

  return <>{children}</>;
};

export default SessionTimeoutHandler;
