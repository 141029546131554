/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  InputField,
  Iconsvg,
  CheckboxField,
  ButtonField,
  SelectField,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ButtonGroupItem,
  Pagination,
} from "@wk/components-react16";
import debounce from "lodash/debounce";
import * as XLSX from "xlsx";

import {
  searchNameMember,
  updateMemberApi,
  viewMember,
  filterMember,
  TitleApi,
  userTypeApi,
  countryListApi,
  stateListApi,
  addMemberApi,
} from "../API/API";
import axios from "axios";
import { useDataContext } from "../utils/DataContext";
import { showNotification } from "../Components/NotificatonPopup/showNotification";
import { useValidation } from "../Components/Hooks/useValidation";
import PageTitle from "../Components/PageTitle";

const JrnlMemberUpdate = () => {
  PageTitle("Journal Member Update");
  const suggestionSelectedRef = useRef(false);
  const { listData = () => {} } = useDataContext() || {};
  const listJournal = listData?.Data?.EditorJournalList ?? [];

  //======================validation================
  const { emailError, handleEmailChange, handleTextInput, setEmailError } =
    useValidation();
  //----------------For Modal========================
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [, setErrorMessage] = useState(null);
  const [userListActive, setUserListActive] = useState(false);
  const [errors, setErrors] = useState({});

  //==============================================================
  const [showBox, setShowBox] = useState(false);
  const [loading, setLoading] = useState(true);
  //for search name input field
  const [suggestions, setSuggetions] = useState([]);
  const [searchName, setSearchName] = useState("");

  //for all input fields and table
  const [searchResults, setSearchResults] = useState([]);
  const [id, setId] = useState("");
  const [updateId, setUpdateId] = useState(0);
  const [email, setEmail] = useState("");
  const [selectedJournalId, setSelectedJournalId] = useState(0);
  const [membershipId, setMembershipId] = useState("");
  const [searchJournal, setSearchJournal] = useState(0);
  const [journalId, setJournalId] = useState(0);
  const [emailSearch, setEmailSearch] = useState("");
  const [memberIdSearch, setMemberIdSearch] = useState("");
  //for view member
  const [viewDetails, setViewDetails] = useState([]);
  const [modalMode, setModalMode] = useState("update");
  //foir userType list
  const [userTypeData, setUserTypeData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(0);
  //foir Title list
  const [titleData, setTitleData] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(0);
  //for countryList
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(0);
  //state list
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState(0);
  //add member state
  //const [salutationId, setSalutationId] = useState(0)
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [printRequired, setPrintRequired] = useState(false);
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [addressThree, setAddressThree] = useState("");
  const [city, setCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pin, setPin] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isActiveFilter, setIsActiveFilter] = useState("active");
  const [, setAllMembers] = useState([]);
  const [, setCreateId] = useState(0);
  const [addressId, setAddressId] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [pageCount, setPageCount] = useState(0);

  const filterAndSetResults = (members, filter) => {
    let filteredMembers;

    if (filter === "active") {
      filteredMembers = members.filter((member) => member.IsActive);
    } else if (filter === "inactive") {
      filteredMembers = members.filter((member) => !member.IsActive);
    } else {
      filteredMembers = members;
    }

    setSearchResults(filteredMembers);
  };

  const handleFilterChange = (e) => {
    setIsActiveFilter(e.target.value);
  };

  //============OPEN BOTH MODALS=================================

  const openModal = (mode) => {
    if (mode === "create") {
      handleResetForm();
    }
    setModalMode(mode);
    setErrors({});
    setIsOpen(true);
    setShowBox(false);
  };

  const handleUserRequest = (origin) => {
    if (
      origin === "esc" ||
      origin === "header" ||
      origin === "footer" ||
      origin === "footer save button"
    ) {
      setIsOpen(false);
    }
  };

  //===============handle input chane==========================
  const handleInputChange = (fieldName, value) => {
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));

    if (fieldName === "selectedTitle") {
      setSelectedTitle(value);
    }
    if (fieldName === "firstName") {
      setFirstName(value);
    }
    if (fieldName === "selectedUser") {
      setSelectedUser(value);
    }
    if (fieldName === "pin") {
      setPin(value);
    }

    if (fieldName === "selectedJournalId") {
      setSelectedJournalId(value);
    }
    if (fieldName === "selectedState") {
      setSelectedState(value);
    }
    if (fieldName === "selectedCountry") {
      setSelectedCountry(value);
    }
    if (fieldName === "city") {
      setCity(value);
    }
    if (fieldName === "email") {
      setEmail(value);
    }
    if (fieldName === "addressOne") {
      setAddressOne(value);
    }
    setShowBox(false);
  };
  // =================================
  const handleUpdateIdChange = (updateId) => {
    setUpdateId(updateId);
    const result = searchResults.filter((x) => x.Id === updateId)[0];
    setMembershipId(result?.MembershipId);
    setSelectedUser(result.UserTypeId);
    setFirstName(result.FirstName);
    setSelectedJournalId(result.JournalId);
    setPrintRequired(result.PrintRequired);
    setIsActive(result.IsActive);
    setAddressOne(result?.JournalMemberAddresses[0]?.Address1);
    setAddressTwo(result?.JournalMemberAddresses[0]?.Address2);
    setAddressThree(result?.JournalMemberAddresses[0]?.Address3);
    setSelectedCountry(result?.JournalMemberAddresses[0]?.CountryId);
    setSelectedState(result?.JournalMemberAddresses[0]?.StateId);
    setPin(result?.JournalMemberAddresses[0]?.Pin);
    setPhoneNumber(result?.JournalMemberAddresses[0]?.Phone);
    setCity(result?.JournalMemberAddresses[0]?.City);
    setMiddleName(result.MiddleName);
    setFullName(result.FullName);
    setSelectedTitle(result.SalutationId);
    setLastName(result.LastName);
    setEmail(result.Email);
    setMobile(result.Mobile);
    setQuantity(result.SubscriptionQuantity);
  };
  //=======================================

  const handleResetForm = () => {
    setUpdateId();
    setCreateId(0);
    setMembershipId("");
    setSelectedUser(0);
    setSelectedTitle(0);
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setFullName("");
    setSelectedJournalId(0);
    setEmail("");
    setMobile("");
    setQuantity(0);
    setPrintRequired(false);
    setAddressOne("");
    setAddressTwo("");
    setAddressThree("");
    setCity("");
    setSelectedCountry(0);
    setPhoneNumber("");
    setSelectedState(0);
    setPin("");
  };
  //=====================================
  const validateForm = () => {
    const errors = {};

    if (!+selectedUser) {
      errors.selectedUser = "User Type is required!";
    }
    // eslint-disable-next-line eqeqeq
    if (!pin && selectedCountry == "106") {
      errors.pin = "Pin is required!";
    }

    if (!+selectedJournalId) {
      errors.selectedJournalId = "Journal name is required!";
    }
    if (!email) {
      errors.email = "Email is required!";
    }
    if (!+selectedTitle) {
      errors.selectedTitle = "Title is required!";
    }

    if (!firstName) {
      errors.firstName = "First Name is required!";
    }

    if (!addressOne) {
      errors.addressOne = "Address 1 is required!";
    }

    if (!+selectedCountry) {
      errors.selectedCountry = "Country is required!";
    }

    if (!city) {
      errors.city = "City is required!";
    }

    if (!+selectedState) {
      errors.selectedState = "State is required!";
    }

    // Additional validation specific to update mode
    if (modalMode === "update") {
      if (!updateId) {
        errors.updateId = "Update ID is required!";
      }
      // Add any additional update-specific validation checks here
    }

    return errors;
  };

  const handleSubmit = async () => {
    const fullNameValue = `${firstName ? firstName.trim() : ""} ${
      middleName ? middleName.trim() : ""
    } ${lastName ? lastName.trim() : ""}`;
    const fullName = fullNameValue.trim();

    const commonData = {
      MembershipId: membershipId,
      UserTypeId: +selectedUser,
      SalutationId: +selectedTitle,
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      FullName: fullName,
      JournalId: +selectedJournalId,
      Email: email,
      Mobile: mobile,
      SubscriptionQuantity: quantity,
      PrintRequired: true,
      IsActive: true,
      JournalMemberAddress: {
        Id: 0,
        JournalMemberId: 0,
        Address1: addressOne,
        Address2: addressTwo,
        Address3: addressThree,
        City: city,
        Pin: pin,
        StateId: +selectedState,
        CountryId: +selectedCountry,
        Phone: phoneNumber,
        AddressTypeId: 2,
        IsActive: true,
      },
    };

    const createData = {
      ...commonData,
      CreatedOn: new Date().toISOString(),
      JournalMemberAddress: {
        ...commonData.JournalMemberAddress,
      },
    };

    const updateData = {
      ...commonData,
      Id: updateId,
      IsActive: isActive,
      PrintRequired: printRequired,
      UpdatedOn: new Date().toISOString(),
      JournalMemberAddress: {
        ...commonData.JournalMemberAddress,
        AddressTypeId: 2,
        Id: addressId,
      },
    };

    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      if (modalMode === "create") {
        await handleCreate(createData);
      } else if (modalMode === "update") {
        await handleUpdate(updateData);
      }
    } catch (error) {
      handleSubmissionError(error);
    }
  };

  const handleCreate = async (createData) => {
    try {
      const response = await axios.post(addMemberApi, createData);
      handleCreateResponse(response, createData);
    } catch (error) {
      handleSubmissionError(error);
    }
  };

  const handleUpdate = async (updateData) => {
    try {
      const response = await axios.put(updateMemberApi, updateData);
      handleUpdateResponse(response, updateData);
    } catch (error) {
      handleSubmissionError(error);
    }
  };

  const handleCreateResponse = (response, createData) => {
    if (response.data) {
      showNotification("success", "Member added successfully");
      setIsOpen(false);
      updateSearchResults(response.data);
      handleSearch(
        response.data.Message,
        createData.Email,
        createData.JournalId,
        createData.MembershipId
      );
      setShowBox(true);
    } else {
      // Handle the case where response.data is not defined
    }
  };

  const handleUpdateResponse = (response, updateData) => {
    if (response.status === 401) {
      window.location.href = "/access-denied";
      return null;
    }
    if (response) {
      setIsOpen(false);
      showNotification("success", "Member updated successfully");
      updateSearchResults(response.data, updateData.Id);
      handleSearch(
        updateData.Id,
        updateData.Email,
        updateData.JournalId,
        updateData.MembershipId
      );
      setShowBox(true);
    }
  };

  const handleSubmissionError = (error) => {
    setIsOpen(false);
    if (error.response && error.response.status === 409) {
      showNotification("warning", "Member already exists!");
    } else {
      showNotification("error", "Member operation failed");
    }
    console.error("An error occurred:", error);
  };

  const updateSearchResults = (updatedData, idToUpdate) => {
    const updatedSearchResults = searchResults ? [...searchResults] : [];
    const index = updatedSearchResults.findIndex(
      (member) => member.Id === (idToUpdate || updatedData.Id)
    );
    if (index !== -1) {
      updatedSearchResults[index] = updatedData;
    } else {
      updatedSearchResults.push(updatedData);
    }
    setSearchResults(updatedSearchResults);
  };

  //========================userTypelist Api=======================================

  const fetchUserTypeData = async () => {
    try {
      const response = await axios.get(userTypeApi);
      setUserTypeData(response.data.Data);
    } catch (error) {
      showNotification("error", "Error fetching data:", error);
    }
  };

  useEffect(
    () => {
      if (isOpen) {
        fetchUserTypeData();
      }
    },
    [isOpen],
    [+selectedUser]
  );

  //========================TitleApi======================
  useEffect(() => {
    fetchTitleData();
  }, [selectedTitle]);

  const fetchTitleData = async () => {
    try {
      const response = await axios.get(TitleApi);
      setTitleData(response.data.Data);
    } catch (error) {
      showNotification("error", "Error fetching title data:", error);
    }
  };

  //==========================state list============================

  const fetchStateList = async (countryId) => {
    const requestBody = { CountryID: countryId };
    try {
      const response = await axios.post(stateListApi, requestBody);
      setStateList(response.data.Data || []);
    } catch (error) {
      console.error("Error fetching state list:", error); // Log error for debugging
      setStateList([]); // Ensure stateList is an empty array on error
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCountryList();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCountry) {
      fetchStateList(selectedCountry);
    }
  }, [selectedCountry]);

  //=========================country list===================================

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(countryListApi);
      setCountryList(response.data.Data || []);
    } catch (error) {
      console.error("Error fetching country list:", error); // Log error for debugging
    }
  };

  //============================search by name============================
  useEffect(() => {
    let debouncedSearchWiki;

    // Define the debounced function
    debouncedSearchWiki = debounce(async () => {
      try {
        if (!suggestionSelectedRef.current && journalId) {
          // Check journalId exists
          const response = await axios.get(
            `${searchNameMember}?name=${searchName}&journalId=${journalId}`
          );
          const dataSearch = response.data.Data.slice(0, 10);
          setSuggetions(dataSearch);
          setUserListActive(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          showNotification("error", "No user is found..!");
          setSearchName("");
        } else {
          showNotification(
            "error",
            "Error fetching data. Please try again later."
          );
        }
      }
    }, 800);

    if (
      (searchName && !suggestionSelectedRef.current) ||
      suggestionSelectedRef.current
    ) {
      debouncedSearchWiki();
      return () => {
        if (debouncedSearchWiki) {
          debouncedSearchWiki.cancel();
        }
        setUserListActive(false);
        setSuggetions([]);
      };
    } else {
      setId(0);
    }
  }, [searchName, journalId]); // Ensure journalId is a dependency

  const handleSuggestionClick = (suggestion) => {
    setUserListActive(false);
    setSuggetions([]);
    setSearchName(suggestion.FullName);
    setId(suggestion.Id);
    suggestionSelectedRef.current = true;
  };
  const handleSearchInputChange = (event) => {
    suggestionSelectedRef.current = false;
    setSearchName(event.target.value);
    setShowBox(false);
  };

  //==============================on search show data table=========================
  const handleSearchJournalChange = (e) => {
    const selectedJournalId = e.target.value;
    if (selectedJournalId !== "0") {
      // Only set if not "0"
      setSearchJournal(selectedJournalId);
      setJournalId(selectedJournalId);
    }
  };

  // Function to sort data based on sorting order
  const sortData = (data, order) => {
    return data.slice().sort((a, b) => {
      const fullNameA = (a.FullName || "").toLowerCase();
      const fullNameB = (b.FullName || "").toLowerCase();

      if (order === "asc") {
        return fullNameA.localeCompare(fullNameB);
      } else {
        return fullNameB.localeCompare(fullNameA);
      }
    });
  };

  const getSortArrow = (sortOrder) => {
    if (sortOrder === "asc") return "↑";
    if (sortOrder === "desc") return "↓";
    return "";
  };

  const renderTableHeader = () => {
    if (searchResults && searchResults.length > 1) {
      return (
        <th
          scope="col"
          onClick={(event) => toggleSortOrder(event)}
          style={{ cursor: "pointer" }}
        >
          Full Name
          {getSortArrow(sortOrder)}
        </th>
      );
    } else {
      return <th scope="col">Full Name</th>;
    }
  };

  const toggleSortOrder = (event) => {
    event.preventDefault();
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    setSearchResults((prevResults) => sortData(prevResults, newSortOrder));
  };

  const handleSearch = async (id, email, journal, memberid) => {
    // Check if at least one search criteria is provided
    if (!journal) {
      setLoading(false);
      showNotification("error", "Please select a journal!");
      return;
    }
    setLoading(true);
    setShowBox(true);

    try {
      const requestData = {
        Id: id,
        MembershipId: memberid,
        JournalId: journal,
        Email: email,
      };
      const response = await fetchSearchResults(requestData);
      setSearchResults(response.data.Data);

      const sortedData = sortData(response.data.Data || [], sortOrder);

      // Filter based on isActiveFilter
      const filteredData = sortedData.filter((member) => {
        if (isActiveFilter === "active") return member.IsActive;
        if (isActiveFilter === "inactive") return !member.IsActive;
        return true; // For "all"
      });

      setSearchResults(filteredData);

      const calculatedPageCount = Math.ceil(filteredData.length / pageSize);
      setPageCount(calculatedPageCount);

      setPage((prevPage) => Math.min(prevPage, calculatedPageCount));

      setAllMembers(sortedData);
      filterAndSetResults(sortedData, isActiveFilter);

      setAddressId(response?.data?.Data[0]?.JournalMemberAddresses[0]?.Id || 0);
    } catch (error) {
      resetFormFields();
      handleSearchError(error);
      setAllMembers([]);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchResults = async (requestData) => {
    const response = await axios.post(filterMember, requestData);
    return response;
  };

  const resetFormFields = () => {
    setSearchName("");
    setMemberIdSearch("");
    setEmailSearch("");
    setSearchJournal(0);
    setEmailError("");
  };

  const handleSearchError = (error) => {
    console.error("Error fetching data:", error);

    if (error.response && error.response.status === 404) {
      // Handle 404 error scenario
      setErrorMessage("Data not found. Please try again.");
    } else {
      // Handle other errors
      setErrorMessage("An error occurred while fetching data!");
    }

    // Reset or adjust other state as needed
    setShowBox(true);
    setSearchResults(null);
  };

  //=======================VIEW MEMBER===================================
  const fetchMemberDetails = async (memberId) => {
    setLoading(true);
    setViewDetails([]);
    try {
      const response = await axios.get(`${viewMember}?id=${memberId}`);
      setViewDetails(response.data.Data);
      setId(0);
      setIsOpenDetails(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  const handleViewMember = (memberId) => {
    setId(memberId);
    fetchMemberDetails(memberId);
    setIsOpenDetails(true);
  };
  //========================detailsModal==================

  const handleUserRequestDetails = (origin) => {
    if (origin === "esc" || origin === "header" || origin === "footer") {
      setIsOpenDetails(false);
    }
  };

  //===============export to excel========================

  const detailsData =
    searchResults?.map((item) => {
      const fullNameParts = [
        item?.FirstName,
        item?.MiddleName,
        item?.LastName,
      ].filter(Boolean);
      const fullName = fullNameParts.join(" ");

      const salutationText =
        titleData.find((title) => title?.Value === item?.SalutationId)?.Text ||
        "";

      return {
        MembershipId: item?.MembershipId,
        UserTypeId: item?.UserType,
        SalutationId: salutationText,
        FullName: fullName,
        JournalId: item?.JournalName,
        Email: item?.Email,
        Mobile: item?.Mobile,
        IsActive: item?.IsActive,
      };
    }) || [];

  const exportToExcel = async () => {
    try {
      setLoading(true);
      const fileName = `Member_details_${getIncrementedNumber()}.xlsx`;
      const ws = XLSX.utils.json_to_sheet(detailsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Members_details");
      XLSX.writeFile(wb, fileName);
      setLoading(false);
      showNotification("success", "Member details downloaded successfully.");
    } catch (error) {
      console.error(error);
      showNotification("error", "Member details download failed.");
      setLoading(false);
    }

    function getIncrementedNumber() {
      if (typeof window.incrementedNumber === "undefined") {
        window.incrementedNumber = 1;
      } else {
        window.incrementedNumber++;
      }
      return padNumber(window.incrementedNumber);
    }
    function padNumber(num) {
      return num < 10 ? `0${num}` : num;
    }
  };

  const startIdx = (page - 1) * pageSize;
  const endIdx = startIdx + pageSize;
  const searchResultsList = searchResults.slice(startIdx, endIdx);

  const renderLoadingRow = () => (
    <tr>
      <td align="center" colSpan={9}>
        <p className="wk-weight-500 wk-text-muted-light">Loading....</p>
      </td>
    </tr>
  );

  const renderNoRecordsFoundRow = () => (
    <tr>
      <td align="center" colSpan={9}>
        <p className="wk-weight-500 wk-text-muted-light">No records found!</p>
      </td>
    </tr>
  );

  const renderMemberRow = (member) => (
    <tr key={uuidv4()}>
      <td>{member.MembershipId}</td>
      <td>{member.UserType}</td>
      <td>
        {titleData.find((title) => title.Value === member.SalutationId)?.Text}
      </td>
      <td>
        {member.FullName ||
          `${member.FirstName} ${member.MiddleName || ""} ${
            member.LastName || ""
          }`}
      </td>
      <td>{member.Email}</td>
      <td>{member.Mobile}</td>
      <td>{member.JournalName}</td>
      <td>
        {member.IsActive ? (
          <p>
            <span className="wk-tag wk-tag-light wk-tag-green">Active</span>
          </p>
        ) : (
          <p>
            <span className="wk-tag">Inactive</span>
          </p>
        )}
      </td>
      <td>
        <div className="tableAction">
          <ButtonField mode={"text"}>
            <button
              className="table-button-np"
              type="button"
              onClick={() => handleViewMember(member.Id)}
            >
              <Iconsvg name="visible" caption="View" />
            </button>
          </ButtonField>
          <ButtonField mode={"text"}>
            <button
              type="button"
              onClick={() => {
                handleUpdateIdChange(member.Id);
                openModal("update");
              }}
            >
              <Iconsvg name="pencil" caption="Update" />
            </button>
          </ButtonField>
        </div>
      </td>
    </tr>
  );

  const renderTableBody = () => (
    <>
      {loading && renderLoadingRow()}
      {!loading && searchResultsList && searchResultsList.length > 0
        ? searchResultsList.map((member) => renderMemberRow(member))
        : !loading && renderNoRecordsFoundRow()}
    </>
  );

  return (
    <div className="wk-page-container wk-page-container-custom">
      <h1>Journal Member</h1>
      <hr className="wk-divider" />
      <div className="wk-grid-stage-workflow-container">
        <div className="wk-grid">
          <div className="wk-column-4">
            <SelectField
              labelFor="JournalId"
              label="Select Journal"
              size="small"
              indicator="required"
            >
              <select
                id="journal-list"
                name="JournalId"
                value={searchJournal}
                onChange={handleSearchJournalChange}
                required
              >
                <option value="">Choose an option</option>
                {listJournal?.map((journal) => (
                  <option key={uuidv4()} value={journal.JournalId}>
                    {journal.JournalName}
                  </option>
                ))}
              </select>
            </SelectField>
          </div>

          <div className="wk-column-4">
            <InputField
              label="MembershipId"
              labelFor="membershipId"
              size="small"
            >
              <input
                type="text"
                id="membershipId"
                name="membershipId"
                value={memberIdSearch}
                placeholder="Enter membership ID"
                onChange={(e) => setMemberIdSearch(e.target.value)}
              />
            </InputField>
          </div>
          <div className="wk-column-4 ">
            <SelectField label="Show Active/Inactive/All" size="small">
              <select value={isActiveFilter} onChange={handleFilterChange}>
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </SelectField>
          </div>
        </div>
        <div className="wk-grid">
          <div className="wk-column-4">
            <InputField
              label="Search Member"
              labelFor="SearchMember"
              size="small"
            >
              <input
                type="search"
                autoComplete="off"
                id="SearchMember"
                name="input-2-1"
                onKeyDown={handleTextInput}
                placeholder="Type member name here..."
                value={searchName}
                onChange={handleSearchInputChange}
              />
            </InputField>
            <div className="user-list-section">
              <ul className={`userlistdiv ${userListActive ? "active" : ""}`}>
                {suggestions.map((suggestion) => (
                  <li key={uuidv4()}>
                    <button
                      type="button"
                      onClick={() => handleSuggestionClick(suggestion)}
                      className="suggestion-button"
                    >
                      {suggestion.FullName}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="wk-column-4">
            <InputField label="Email" labelFor="email" size="small">
              <input
                type="text"
                id="email"
                name="email"
                value={emailSearch}
                placeholder="Enter member's email ID"
                onChange={(e) => {
                  handleEmailChange(e.target.value);
                  setEmailSearch(e.target.value);
                }}
              />
              {emailError && (
                <span style={{ color: "red" }} className="error">
                  {emailError}
                </span>
              )}
            </InputField>
          </div>
        </div>
        <div className="wk-grid">
          <div className="wk-column-12">
            <div className="card-sub-count">
              <div>
                <ButtonField size={"small"}>
                  <button
                    onClick={() => openModal("create")}
                    type="button"
                    style={{ marginRight: "5px" }}
                  >
                    Add new member
                  </button>
                </ButtonField>
                <ButtonField size={"small"}>
                  <button
                    type="button"
                    style={{ marginRight: "5px" }}
                    onClick={() =>
                      handleSearch(
                        id,
                        emailSearch,
                        searchJournal,
                        memberIdSearch
                      )
                    }
                  >
                    Search
                  </button>
                </ButtonField>
              </div>
              <div>
                {searchResults && showBox && searchResults.length > 0 && (
                  <ButtonField size="small" iconPosition={"left"}>
                    <button type="button" onClick={exportToExcel}>
                      <Iconsvg name="download-line"></Iconsvg>
                      Export To Excel{" "}
                    </button>
                  </ButtonField>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============================Table started ===============================*/}
      {showBox && (
        <>
          <div className="wk-table-permit-overflow wk-cust-table">
            <h3 className="wk-sub-title">Journal Member List</h3>
            <table className="wk-table wk-table-striped">
              <thead>
                <tr>
                  <th scope="col">Membership No.</th>
                  <th scope="col">User Type</th>
                  <th scope="col">Salutation</th>
                  {renderTableHeader()}
                  <th scope="col">Email</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Journal Name</th>
                  <th className="wk-is-centered" scope="col">
                    Status
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>{renderTableBody()}</tbody>
            </table>
          </div>
          <div className="wk-pagination">
            {searchResults.length > pageSize && (
              <Pagination
                pageCount={pageCount}
                pageIndex={page}
                onOnNextArrowClick={() => setPage((prevPage) => prevPage + 1)}
                onOnPreviousArrowClick={() =>
                  setPage((prevPage) => prevPage - 1)
                }
                onOnPageClick={(page) => setPage(page)}
              />
            )}
          </div>
        </>
      )}

      <div className="custom-modal">
        {isOpenDetails && (
          <Modal
            isOpen={isOpenDetails}
            onUserRequest={(event) => handleUserRequestDetails(event)}
          >
            <>
              <ModalHeader
                isFullscreen
                onUserRequest={() => handleUserRequestDetails("header")}
              >
                View Member Details
              </ModalHeader>
              {loading ? (
                <ModalBody descriptionId="modal-id-002">
                  <p className="wk-weight-500 wk-text-muted-light">
                    Loading.....
                  </p>
                </ModalBody>
              ) : (
                <ModalBody descriptionId="modal-id-002">
                  <div id="viewJournalMemberModalView">
                    <div>
                      <table className="wk-table wk-table-striped">
                        <tbody>
                          <tr>
                            <th>Membership No.</th>
                            <td>{viewDetails.MembershipId}</td>
                          </tr>
                          <tr>
                            <th>User Type</th>
                            <td>{viewDetails.UserType}</td>
                          </tr>

                          <tr>
                            <th>Full Name</th>
                            <td>
                              {viewDetails.FullName ||
                                `${viewDetails.FirstName} ${
                                  viewDetails.MiddleName || ""
                                } ${viewDetails.LastName || ""}`}
                            </td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{viewDetails.Email}</td>
                          </tr>
                          <tr>
                            <th>Mobile</th>
                            <td>{viewDetails.Mobile}</td>
                          </tr>
                          <tr>
                            <th>Journal Name</th>
                            <td>{viewDetails.JournalName}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ModalBody>
              )}
              <ModalFooter>
                <ButtonGroup mode="static">
                  <ButtonGroupItem slot="buttonGroupItem">
                    <ButtonField mode={"text"}>
                      <button
                        type="button"
                        id="cancel"
                        onClick={() => handleUserRequestDetails("footer")}
                      >
                        Cancel
                      </button>
                    </ButtonField>
                  </ButtonGroupItem>
                </ButtonGroup>
              </ModalFooter>
            </>
          </Modal>
        )}
      </div>

      {/*============================ Modal Started============================ */}
      <div className="MemberCreateModal">
        {isOpen && (
          <Modal
            isOpen={isOpen}
            onClick={() => setIsOpen(false)}
            onUserRequest={(event) => handleUserRequest(event)}
            mobileMode="default"
            width="840px"
          >
            <ModalHeader onUserRequest={() => handleUserRequest("header")}>
              {modalMode === "create"
                ? "Add New Member Details"
                : "Update Member Details"}
            </ModalHeader>
            <ModalBody descriptionId="modal-id-002">
              <div className="memberUpdateModal">
                <h4 className="mb">Personal Details</h4>
                <br />
                <div className="wk-grid-stage-workflow-container">
                  <div className="wk-grid">
                    <div className="wk-column-4 ">
                      <InputField
                        label="Membership No."
                        labelFor="Membership No"
                        size="small"
                      >
                        <input
                          type="text"
                          id="Membership No"
                          name="MembershipId"
                          value={membershipId || ""}
                          onChange={(e) => setMembershipId(e.target.value)}
                        />
                      </InputField>
                    </div>
                    <div className="wk-column-4">
                      <SelectField
                        label="User Type"
                        labelFor="User Type"
                        size="small"
                        indicator="required"
                      >
                        <select
                          id="User Type"
                          name="UserTypeId"
                          required
                          //onChange={handleUserTypeSelectChange}
                          onChange={(e) =>
                            handleInputChange("selectedUser", e.target.value)
                          }
                          value={selectedUser || ""}
                        >
                          <option value="">Select User Type</option>
                          {userTypeData.map((item) => (
                            <option key={uuidv4()} value={item.Id}>
                              {item.Users}
                            </option>
                          ))}
                        </select>
                      </SelectField>

                      {errors.selectedUser && (
                        <div className="wk-primary-red wk-size-2 margin-top-14">
                          {errors.selectedUser}
                        </div>
                      )}
                    </div>
                    <div className="wk-column-4">
                      <SelectField
                        label="Title"
                        labelFor="Title"
                        size="small"
                        indicator="required"
                      >
                        <select
                          id="Title"
                          name="SalutationId"
                          //onChange={handleTitleSelectChange}
                          onChange={(e) =>
                            handleInputChange("selectedTitle", e.target.value)
                          }
                          value={selectedTitle || ""}
                          required
                        >
                          <option value="">Select Title</option>
                          {titleData.map((title) => (
                            <option key={uuidv4()} value={title.Value}>
                              {title.Text}
                            </option>
                          ))}
                        </select>
                      </SelectField>
                      {errors.selectedTitle && (
                        <div className="wk-primary-red wk-size-2 margin-top-14">
                          {errors.selectedTitle}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="wk-grid">
                    <div className="wk-column-4">
                      <InputField
                        label="First Name"
                        labelFor="First Name"
                        size="small"
                        indicator="required"
                      >
                        <input
                          type="text"
                          id="First Name"
                          name="FirstName"
                          value={firstName || ""}
                          onChange={(e) =>
                            handleInputChange("firstName", e.target.value)
                          }
                          required
                        />
                      </InputField>
                      {errors.firstName && (
                        <div className="wk-primary-red wk-size-2 margin-top-14">
                          {errors.firstName}
                        </div>
                      )}
                    </div>
                    <div className="wk-column-4">
                      <InputField
                        label="Middle Name"
                        labelFor="Middle Name"
                        size="small"
                      >
                        <input
                          type="text"
                          id="Middle Name"
                          name="MiddleName"
                          value={middleName || ""}
                          onChange={(e) => setMiddleName(e.target.value)}
                        />
                      </InputField>
                    </div>
                    <div className="wk-column-4">
                      <InputField
                        label="Last Name"
                        labelFor="Last Name"
                        size="small"
                      >
                        <input
                          type="text"
                          id="Last Name"
                          //onKeyDown={handleNumberInput}
                          name="LastName"
                          value={lastName || ""}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </InputField>
                    </div>
                  </div>
                  <div className="wk-grid">
                    <div className="wk-column-4">
                      <InputField
                        label="Full Name"
                        labelFor="Full Name"
                        size="small"
                      >
                        <input
                          type="text"
                          id="Full Name"
                          name="FullName"
                          value={fullName || ""}
                          readOnly
                          aria-readonly="true"
                        />
                      </InputField>
                    </div>
                    <div className="wk-column-4">
                      <SelectField
                        label="Journal Name"
                        labelFor="Journal Name"
                        size="small"
                        indicator="required"
                      >
                        <select
                          id="Journal Name"
                          name="JournalName"
                          value={selectedJournalId}
                          //onChange={handleJournalChange}
                          onChange={(e) =>
                            handleInputChange(
                              "selectedJournalId",
                              e.target.value
                            )
                          }
                          required
                        >
                          <option value="">Select Journal</option>
                          {listJournal?.map((journal) => (
                            <option key={uuidv4()} value={journal.JournalId}>
                              {journal.JournalName}
                            </option>
                          ))}
                        </select>
                      </SelectField>
                      {errors.selectedJournalId && (
                        <div className="wk-primary-red wk-size-2 margin-top-14">
                          {errors.selectedJournalId}
                        </div>
                      )}
                    </div>
                    <div className="wk-column-4 wk-column-12-xs width270">
                      <InputField
                        label="Email"
                        labelFor="Email"
                        size="small"
                        indicator="required"
                      >
                        <input
                          type="text"
                          id="Email"
                          name="Email"
                          value={email || ""}
                          onChange={(e) => {
                            handleInputChange("email", e.target.value);
                            handleEmailChange(e.target.value);
                          }}
                          required
                        />
                        {emailError && (
                          <span
                            style={{ color: "red" }}
                            className="error wk-size-2 margin-top-14"
                          >
                            {emailError}
                          </span>
                        )}
                      </InputField>
                      {errors.email && (
                        <div className="wk-primary-red wk-size-2 margin-top-14">
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="wk-grid">
                    <div className="wk-column-4 wk-column-12-xs width270">
                      <InputField
                        label="Mobile Number"
                        labelFor="Mobile Number"
                        size="small"
                      >
                        <input
                          type="number"
                          id="Mobile Number"
                          name="Mobile"
                          value={mobile || ""}
                          onKeyDown={(evt) =>
                            evt.key === "e" && evt.preventDefault()
                          }
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </InputField>
                    </div>
                    <div className="wk-column-4 wk-column-12-xs width270">
                      <InputField
                        label="Quantity"
                        labelFor="Quantity"
                        size="small"
                      >
                        <input
                          type="number"
                          id="Quantity"
                          name="SubscriptionQuantity"
                          value={quantity || ""}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                      </InputField>
                    </div>
                    <div
                      className="wk-column-4 wk-column-12-xs"
                      style={{ marginTop: "25px" }}
                    >
                      <CheckboxField label="Print Required">
                        <input
                          name="PrintRequired"
                          type="checkbox"
                          checked={printRequired}
                          onChange={(e) => setPrintRequired(e.target.checked)}
                        />
                      </CheckboxField>
                    </div>
                  </div>
                  <br />
                  <div>
                    <CheckboxField label="Is Active">
                      <input
                        name="IsActive"
                        type="checkbox"
                        checked={isActive}
                        onChange={(e) => setIsActive(e.target.checked)}
                      />
                    </CheckboxField>
                  </div>
                  <br />
                </div>
                <h4 className="mb">Delivery Address Details</h4>
                <br />
                <div className="wk-grid-stage-workflow-container">
                  <div className="wk-grid">
                    <div className="wk-column-4">
                      <InputField
                        label="Address 1"
                        labelFor="Address 1"
                        size="small"
                        indicator="required"
                      >
                        <input
                          type="text"
                          id="Address 1"
                          name="Address1"
                          value={addressOne || ""}
                          onChange={(e) =>
                            handleInputChange("addressOne", e.target.value)
                          }
                          //onChange={(e) => setAddressOne(e.target.value)}
                          required
                        />
                      </InputField>
                      {errors.addressOne && (
                        <div className="wk-primary-red wk-size-2 margin-top-14">
                          {errors.addressOne}
                        </div>
                      )}
                    </div>
                    <div className="wk-column-4">
                      <InputField
                        label="Address 2"
                        labelFor="Address 2"
                        size="small"
                      >
                        <input
                          type="text"
                          id="Address 2"
                          name="Address2"
                          value={addressTwo || ""}
                          onChange={(e) => setAddressTwo(e.target.value)}
                        />
                      </InputField>
                    </div>
                    <div className="wk-column-4">
                      <InputField
                        label="Address 3"
                        labelFor="Address 3"
                        size="small"
                      >
                        <input
                          type="text"
                          id="Address 3"
                          name="Address3"
                          value={addressThree || ""}
                          onChange={(e) => setAddressThree(e.target.value)}
                        />
                      </InputField>
                    </div>
                  </div>
                  <div className="wk-grid">
                    <div className="wk-column-4">
                      <InputField
                        label="City"
                        labelFor="City"
                        size="small"
                        indicator="required"
                      >
                        <input
                          type="text"
                          id="City"
                          name="City"
                          value={city || ""}
                          //onChange={(e) => setCity(e.target.value)}
                          onChange={(e) =>
                            handleInputChange("city", e.target.value)
                          }
                          required
                        />
                      </InputField>
                      {errors.city && (
                        <div className="wk-primary-red wk-size-2 margin-top-14">
                          {errors.city}
                        </div>
                      )}
                    </div>
                    <div className="wk-column-4">
                      <SelectField
                        label="Country"
                        labelFor="Country"
                        size="small"
                        indicator="required"
                      >
                        <select
                          id="Country"
                          name="Country"
                          required
                          value={selectedCountry}
                          //onChange={handleCountrySelectChange}
                          onChange={(e) =>
                            handleInputChange("selectedCountry", e.target.value)
                          }
                        >
                          <option value="">Select Country</option>
                          {countryList.map((country) => (
                            <option key={uuidv4()} value={country.Id}>
                              {country.Country1}
                            </option>
                          ))}
                        </select>
                      </SelectField>
                      {errors.selectedCountry && (
                        <div className="wk-primary-red wk-size-2 margin-top-14">
                          {errors.selectedCountry}
                        </div>
                      )}
                    </div>
                    <div className="wk-column-4">
                      <InputField
                        label="Phone Number"
                        labelFor="Phone Number"
                        size="small"
                      >
                        <input
                          type="number"
                          id="Phone Number"
                          name="Phone"
                          onKeyDown={(evt) =>
                            evt.key === "e" && evt.preventDefault()
                          }
                          value={phoneNumber || ""}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </InputField>
                    </div>
                  </div>
                  <div className="wk-grid">
                    <div className="wk-column-4">
                      <SelectField
                        label="State"
                        labelFor="State"
                        size="small"
                        indicator={selectedCountry ? "required" : ""}
                      >
                        <select
                          id="State"
                          name="StateId"
                          required={!!selectedCountry}
                          onChange={(e) =>
                            handleInputChange("selectedState", e.target.value)
                          }
                          value={selectedState}
                        >
                          <option style={{ fontWeight: "bold" }}>
                            Select State
                          </option>
                          {Array.isArray(stateList) && stateList.length > 0 ? (
                            stateList.map((state) => (
                              <option key={uuidv4()} value={state.Value}>
                                {state.Text}
                              </option>
                            ))
                          ) : (
                            <option disabled>No states available</option>
                          )}
                        </select>
                      </SelectField>
                      {errors.selectedState && (
                        <div className="wk-primary-red wk-size-2 margin-top-14">
                          {errors.selectedState}
                        </div>
                      )}
                    </div>

                    <div className="wk-column-4">
                      <InputField
                        label="Pin"
                        labelFor="Pin"
                        size="small"
                        // eslint-disable-next-line eqeqeq
                        indicator={selectedCountry == "106" ? "required" : ""}
                      >
                        <input
                          type="text"
                          id="Pin"
                          name="Pin"
                          value={pin || ""}
                          onChange={(e) =>
                            handleInputChange("pin", e.target.value)
                          }
                          required
                        />
                      </InputField>
                      {selectedCountry == "106" &&
                        errors.pin &&
                        pin.trim() == "" && (
                          <div className="wk-primary-red wk-size-2 margin-top-14">
                            {errors.pin}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup mode="static">
                <ButtonGroupItem slot="buttonGroupItem">
                  <ButtonField mode={"text"}>
                    <button
                      type="button"
                      id="cancel"
                      onClick={() => handleUserRequest("footer")}
                    >
                      Cancel
                    </button>
                  </ButtonField>
                </ButtonGroupItem>
                <ButtonGroupItem slot="buttonGroupItem">
                  <ButtonField>
                    <button type="button" id="save" onClick={handleSubmit}>
                      Save
                    </button>
                  </ButtonField>
                </ButtonGroupItem>
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default React.memo(JrnlMemberUpdate);
