import React from "react";
import { formatDate } from "../utils/paymentUtils";

const Table = ({ loading, data, columns }) => {
  return (
    <div className="wk-table-permit-overflow">
      <table className="wk-table wk-table-striped">
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.accessor} scope="col">
                {col.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td align="center" colSpan={columns.length}>
                <p className="wk-weight-500 wk-text-muted-light">Loading....</p>
              </td>
            </tr>
          ) : data.length > 0 ? (
            data.map((row, index) => (
              <tr key={index}>
                {columns.map((col) => (
                  <td key={col.accessor}>
                    {col.accessor === "PaymentDate" ||
                    col.accessor === "InvoiceDate"
                      ? formatDate(row[col.accessor])
                      : row[col.accessor]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td align="center" colSpan={columns.length}>
                <p className="wk-weight-500 wk-text-muted-light">
                  No records found!
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
