import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header";
import Login from "./Views/Login";
import ChangePassword from "./Views/ChangePassword";
import CustomManuscript from "./Views/CustomManuscript";
import JrnlMemberUpload from "./Views/JrnlMemberUpload";
import JrnlMemberUpdate from "./Views/JrnlMemberUpdate";
import PageNotFound from "./Views/PageNotFound";
import Dashboard from "./Components/Dashboard";
import MemberList from "./Components/MemberList";
import MemberReport from "./Views/MemberReport";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import React, { useState, useEffect } from "react";
import { DataProvider } from "./utils/DataContext";
import Payments from "./Views/Payments";
import Help from "./Views/Help";
import { createRequestInterceptor } from "./Authentication/RefreshInterceptor";
import axios from "axios";
import "./App.css";
import AccessDenied from "./Views/AccessDenied";
import * as Sentry from "@sentry/react";
import SessionTimeoutHandler from "./Components/SessionTimeoutHandler";
import { generateCSPDirective } from "./utils/cspHelperUtils";

function App() {
  const [forgotPasswordUrl, setForgotPasswordUrl] = useState("");
  const [sessionTimeout, setSessionTimeout] = useState(null);
  const [config, setConfig] = useState();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await getConfig();
        setForgotPasswordUrl(config.forgotPasswordLink);
        setSessionTimeout(config.sessionTimeout || 1200000); // Use default if not found
      } catch (e) {
        console.error("Failed to fetch configuration:", e);
        setSessionTimeout(1200000); // Fallback to default on error
      }
    };

    fetchConfig();
  }, []);

  const getConfig = async () => {
    try {
      const baseURL = window.location.origin;
      const result = await axios.get(baseURL + "/js/config.json");
      setForgotPasswordUrl(result.data.forgotPasswordLink);
      return result.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const setupSentry = (sentryConfig) => {
    Sentry.init({
      dsn: sentryConfig.dsn,
      tracesSampleRate: sentryConfig.tracesSampleRate,
      environment: sentryConfig.environment,
      release: sentryConfig.release,
      initialScope: {
        tags: { App: sentryConfig.tags },
      },
    });

    let data = sessionStorage.getItem("apiData");
    if (data) {
      const userId = JSON.parse(data).UserId;
      Sentry.setUser({ id: userId });
    }
  };

  useEffect(() => {
    loadCCTScriptOne();
    loadCCTScriptTwo();
    loadNewrelic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadNewrelic = () => {
    const script = document.createElement("script");

    script.src = "./js/newrelic.js";

    document.body.appendChild(script);
  };

  const loadCCTScriptOne = async () => {
    const script = document.createElement("script");
    const configData = await getConfig();
    const cctkey = configData ? configData["cctkey"] : "";
    script.src = `https://cdn.cookielaw.org/consent/${cctkey}/OtAutoBlock.js`;
    document.body.appendChild(script);
  };

  const loadCCTScriptTwo = async () => {
    const script = document.createElement("script");
    const configData = await getConfig();
    const cctkey = configData ? configData["cctkey"] : "";
    script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    script.setAttribute("data-domain-script", cctkey);
    document.body.appendChild(script);
  };

  //CCT
  useEffect(() => {
    (async () => {
      const configData = await getConfig();
      const cookie = configData ? configData["cookieconsent"] : "";
      const consenttimeout = configData ? configData["consenttimeout"] : "";
      if (cookie === "false") {
        setTimeout(() => {
          const onetrustElement = document.querySelector(
            "#onetrust-consent-sdk"
          );
          if (onetrustElement) {
            onetrustElement.style.display = "none";
          }
          const onetrustButton = document.querySelector(
            ".ot-sdk-show-settings"
          );
          if (onetrustButton) {
            onetrustButton.style.display = "none";
          }
        }, consenttimeout);
      }
    })();
  }, []);
  //CCT

  useEffect(() => {
    (async () => {
      try {
        const configData = await getConfig();
        setConfig(configData);
        sessionStorage.setItem("config", JSON.stringify(configData));
      } catch (error) {
        console.error("Error fetching or storing configData:", error);
      }
    })();
  }, []);

  const insertCSPMetaTag = (cspConfig) => {
    const metaTag = document.createElement("meta");
    metaTag.httpEquiv = "Content-Security-Policy";
    metaTag.content = generateCSPDirective(cspConfig);
    metaTag.setAttribute("data-testid", "csp-meta-tag"); // Add data-testid attribute

    // Ensure no duplicate meta tags
    const existingMetaTag = document.head.querySelector(
      'meta[http-equiv="Content-Security-Policy"]'
    );
    if (existingMetaTag) {
      document.head.removeChild(existingMetaTag);
    }

    document.head.appendChild(metaTag);
  };

  useEffect(() => {
    config?.csp && insertCSPMetaTag(config.csp);
  }, [config]);

  useEffect(() => {
    const setupConfigDataAndInterceptor = async () => {
      try {
        const data = await getConfig();
        setConfig(data);
        setupSentry(data.sentry);
        sessionStorage.setItem("config", JSON.stringify(data));
        createRequestInterceptor();
      } catch (error) {
        console.error("Error fetching or storing configData:", error);
      }
    };

    setupConfigDataAndInterceptor();
  }, []);

  return (
    <>
      {config && (
        <main>
          <DataProvider>
            <Router>
              <Header />
              <SessionTimeoutHandler sessionTimeout={sessionTimeout}>
                <Routes>
                  <Route
                    path="/"
                    exact={true}
                    element={<Login forgotPasswordUrl={forgotPasswordUrl} />}
                  />
                  <Route path="/" exact={true} element={<Login />} />
                  <Route path="/changePassword" element={<ChangePassword />} />
                  <Route element={<ProtectedRoutes />}>
                    <Route element={<Dashboard />} path="/dashboard" />
                    <Route
                      path="/journal-member-upload"
                      element={<JrnlMemberUpload />}
                      handleValidation
                    />
                    <Route
                      path="/journal-member-update"
                      element={<JrnlMemberUpdate />}
                    />
                    <Route
                      path="/custom-manuscript-rates"
                      element={<CustomManuscript />}
                    />
                    <Route path="/memberlist" element={<MemberList />} />
                    <Route path="/memberreport" element={<MemberReport />} />
                    <Route path="/payments" element={<Payments />} />
                    <Route path="/help" element={<Help />} />
                  </Route>
                  <Route path="*" element={<PageNotFound />} />

                  <Route path="access-denied" element={<AccessDenied />} />
                </Routes>
              </SessionTimeoutHandler>
              <Footer />
            </Router>
          </DataProvider>
        </main>
      )}
    </>
  );
}

export default Sentry.withProfiler(App);
