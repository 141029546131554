import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ButtonField,
  InputField,
  PasswordField,
  Iconsvg,
} from "@wk/components-react16";
import { showNotification } from "../Components/NotificatonPopup/showNotification";
import { loginAPI, loginHistoryAPI } from "../API/API";
import axios from "axios";
import { useDataContext } from "../utils/DataContext";
import { useValidation } from "../Components/Hooks/useValidation";
import * as Sentry from "@sentry/react";

const Login = ({ forgotPasswordUrl }) => {
  const navigate = useNavigate();
  const [, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [type2, setType2] = useState("password");
  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [password, setPassword] = useState("");
  const [errorPass, setErrorPass] = useState(null);
  const { isValidEmail } = useValidation();
  const { setListData = () => {} } = useDataContext() || {};

  useEffect(() => {
    const accessToken = sessionStorage.getItem("access_token");
    if (accessToken) {
      setIsLoggedIn(true);
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleLoginHistory = async (userId) => {
    try {
      const response = await axios.post(loginHistoryAPI, {
        UserId: userId,
        LoginTime: new Date().toISOString(),
        LogoutTime: null,
        ApplicationId: 3,
      });

      return response.data;
    } catch (error) {
      console.error("Error recording login history:", error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!email || !password) {
      setIsLoading(false);
      handleBlurName();
      handleBlurPassword();
      return;
    }

    const minLength = 8;
    const maxLength = 15;
    if (password.length < minLength || password.length > maxLength) {
      setIsLoading(false);
      setErrorPass({
        label: `Password must be between ${minLength} and ${maxLength} characters.`,
      });
      return;
    }

    try {
      const response = await axios.post(loginAPI, {
        Email: email,
        Password: password,
        Role: "EditorialDashboard",
      });

      if (response.status === 200) {
        sessionStorage.setItem("Email", email);
        setIsLoading(false);
        showNotification("success", "Logged in successfully!");
        setIsLoggedIn(true);
        setListData(response.data.Data);
        Sentry.setUser({
          id: response.data.Data.UserId,
        });
        const loginHistorySuccess = await handleLoginHistory(
          response.data.Data.UserId
        );
        if (!loginHistorySuccess) {
          console.error("Failed to record login history.");
        }
        const accessToken = response.data.Data.AccessToken;
        const refreshToken = response.data.Data.RefreshToken;
        sessionStorage.setItem("access_token", accessToken);
        sessionStorage.setItem("refresh_token", refreshToken);
        sessionStorage.setItem("apiData", JSON.stringify(response.data));
        sessionStorage.setItem("isLoggedIn", "true");
        navigate("/dashboard");
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (
        error.response?.status === 401 &&
        error.response.data.Message === "Invalid login role"
      ) {
        navigate("/access-denied");
      } else if (
        error?.response?.status === 401 &&
        error?.response?.data.Message === "Invalid Credentials !!!"
      ) {
        showNotification(
          "error",
          "Invalid credentials. Please check your email and password."
        );
      } else if (
        error?.response?.status === 404 &&
        error?.response?.data.Message.includes("Account not found")
      ) {
        showNotification(
          "error",
          "Invalid credentials. Please check your email and password."
        );
        setIsLoading(false);
      } else if (error?.response?.status === 500) {
        showNotification(
          "error",
          "Internal server error. Please try again later."
        );
      } else {
        showNotification("error", "An error occurred while trying to log in.");
      }
      setIsLoading(false);
    }
  };
  const handleModeChange = () => {
    setType2((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const handleBlurName = () => {
    setEmailTouched(true);
  };

  const handleChangePassword = (event) => {
    const value = event.target.value;
    setPassword(value);
  };

  const handleBlurPassword = () => {
    setErrorPass(
      password.trim() === "" ? { label: "Password is required" } : null
    );
  };
  const hasErrorName = emailTouched && email.trim() === "";
  return (
    <div className="wk-page-container wk-page-container-custom login-contianer">
      <div className="wk-grid-stage-workflow-container">
        <div className="wk-grid wk-grid-flex">
          <div className="wk-column-5">
            <form onSubmit={handleSubmit} className="wk-login-form">
              <h1>Login</h1>
              <InputField
                label="Email"
                labelFor="Email+"
                size="small"
                mode="secondary"
                indicator="required"
                status={
                  hasErrorName
                    ? "error"
                    : emailTouched
                    ? email.trim() === ""
                      ? "error"
                      : isValidEmail(email)
                      ? "success"
                      : "error"
                    : ""
                }
                errors={
                  hasErrorName
                    ? [{ label: "Email is required" }]
                    : emailTouched && !isValidEmail(email)
                    ? [{ label: "Invalid email format" }]
                    : []
                }
              >
                <input
                  //type={type2}
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailTouched(true);
                  }}
                  onBlur={handleBlurName}
                  value={email}
                />
              </InputField>

              <PasswordField
                labelFor="password-id-3-2"
                label="Password"
                size="small"
                mode="secondary"
                indicator="required"
                type={type2}
                status={
                  errorPass ? "error" : password.trim() !== "" ? "success" : ""
                }
                error={errorPass}
                onModeChange={handleModeChange}
              >
                <input
                  type={type2}
                  placeholder="Password"
                  id="password-id-3-2"
                  name="password-3-2"
                  value={password}
                  onChange={handleChangePassword}
                  onBlur={handleBlurPassword}
                />
              </PasswordField>

              <ButtonField size={"large"}>
                <button
                  type="submit"
                  className="wk-login-button wk-loading-button wk-button wk-button-icon-right"
                  disabled={isLoading}
                >
                  {isLoading ? "Logging in..." : "Login"}
                  {isLoading && (
                    <Iconsvg name="spinner-alt" isSpin isFill></Iconsvg>
                  )}
                </button>
              </ButtonField>

              <a
                className="wk-button wk-button-text wk-text-left"
                href={forgotPasswordUrl}
                rel="noreferrer"
                target="_blank"
              >
                I forgot my password
              </a>
            </form>
          </div>
          <div className="wk-column-7">
            <div className="wk-login-content-right wk-margins">
              <div className="wk-notification wk-is-open wk-notification-info">
                <p className="wk-notification-title">
                  Welcome to Editorial Console
                </p>
                <p className="wk-notification-title">
                  Your hub for managing Member details, Article Type Rates,
                  Manuscript Submission, Processing, and Color Reproduction Fees
                  for Medknow Journals.
                </p>
                <p className="wk-notification-content">
                  If you have any questions or concerns&#44; contact us at
                  <br />
                  <a href="mailto:wkhlrpmedknow_techsupport@wolterskluwer.com">
                    wkhlrpmedknow_techsupport@wolterskluwer.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
