// cspHelper.js
export function generateCSPDirective(cspConfig) {
  const directives = [];

  for (const [directive, sources] of Object.entries(cspConfig)) {
    directives.push(
      `${directive.replace(/([A-Z])/g, "-$1").toLowerCase()} ${sources.join(
        " "
      )};`
    );
  }

  return directives.join(" ");
}
