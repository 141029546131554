import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  CardTile,
  CardBody,
  Tag,
} from "@wk/components-react16";
import { subscribersAPI } from "../API/API";
import { showNotification } from "../Components/NotificatonPopup/showNotification"; 
 
const SubscriberCards = (props) => { 
  const { journalId, journalName } = props;
  const [subscriberData, setSubscriberData] = useState({});
  const [loading, setLoading] = useState(true);
   
  useEffect(() => {
    const fetchSubscriberData = async (journalId) => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${subscribersAPI}?JournalId=${journalId}`
        );

        setSubscriberData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        setSubscriberData({ Data: [] });
      }
    };
    if (journalId > 0) {
      fetchSubscriberData(journalId);
    }
  }, [journalId]);

  const subscribers = subscriberData.Data;
  let categorizedSubscribers =
    subscribers &&
    subscribers.reduce((result, subscriber) => {
      const { SubscriptionType, ProductType, TotalCount, Type } = subscriber;
      const category = `${SubscriptionType}`;
      if (!result[category]) {
        result[category] = {
          SubscriptionType,
          Type,
          TotalCountOnline: 0,
          TotalCountPrint: 0,
        };
      }
      if (ProductType === "Online") {
        result[category].TotalCountOnline +=
          TotalCount !== null ? TotalCount : 0;
      } else if (ProductType === "Print") {
        result[category].TotalCountPrint +=
          TotalCount !== null ? TotalCount : 0;
      }
      return result;
    }, {});

  // if (SubscriptionType !== 'Individual') {
  //   result[category] = {
  //     SubscriptionType: 'Individual',
  //     TotalCountOnline: 0,
  //     TotalCountPrint: 0,
  //   };
  // }

  // if (SubscriptionType !== 'Institutional') {
  //   result[category] = {
  //     SubscriptionType: 'Institutional',
  //     TotalCountOnline: 0,
  //     TotalCountPrint: 0,
  //   };
  // }

  if (
    categorizedSubscribers &&
    !Object.keys(categorizedSubscribers).includes("Institution")
    ) {
    const Institution = {
      SubscriptionType: "Institution",
      TotalCountOnline: 0,
      TotalCountPrint: 0,
      Type: "Subscribers"
    };
    categorizedSubscribers = { ...categorizedSubscribers, Institution };
    }
  if (
    categorizedSubscribers &&
    !Object.keys(categorizedSubscribers).includes("Individual")
  ) {
    const Individual = {
      SubscriptionType: "Individual",
      TotalCountOnline: 0,
      TotalCountPrint: 0,
      Type: "Subscribers"
    };
    categorizedSubscribers = { ...categorizedSubscribers, Individual };
  }
  if (
    categorizedSubscribers &&
    !Object.keys(categorizedSubscribers).includes("Single Issue")
  ) {
    const Single = {
      SubscriptionType: "Single Issue",
      TotalCountOnline: 0,
      TotalCountPrint: 0,
      Type: "Subscribers"
    };
    categorizedSubscribers = { ...categorizedSubscribers, Single };
  }
  if (
    categorizedSubscribers &&
    !Object.keys(categorizedSubscribers).includes("Agent")
  ) {
    const Agent = {
      SubscriptionType: "Agent",
      TotalCountOnline: 0,
      TotalCountPrint: 0,
      Type: "Subscribers"
    };
    categorizedSubscribers = { ...categorizedSubscribers, Agent };
  }
  //categorizedSubscribers && console.log(Object.keys(categorizedSubscribers))
   
  const handleSubscriberClick = (subscriber, JournalId, journalName) => { 
    const totalCount = subscriber.TotalCountOnline + subscriber.TotalCountPrint;
    if (totalCount > 0) {          
      localStorage.setItem("journalName", journalName);     
      window.open(`/memberlist?Type=Subscribers&UserType=${subscriber.SubscriptionType}&JournalId=${journalId}`,
      );      
    } else {      
      showNotification("error", "No Members found.");       
    } 
  };

  return (
    <>
      {loading ? (
        <p className="wk-weight-500 wk-text-muted-light">Loading....</p>
      ) : Object.values(categorizedSubscribers) &&
        Object.values(categorizedSubscribers).length > 0 ? (
        <div className="wk-grid">
          {Object.values(categorizedSubscribers).map((subscriber, index) => (
            <div className="wk-column-3  text-light" key={index}>
              <Card>
                <div
                  className="JournalCard"
                  style={{
                    backgroundColor: ["#007AC3", "#85BC20", "#940C72", "#757575"][
                      index % 4
                    ],
                  }}
                >
                  <CardContent>
                    <div className="wk-journalcard-size">
                      <Tag>
                        <p className="wk-weight-500 wk-cards-toptitle">
                          {subscriber.SubscriptionType}
                        </p>
                      </Tag>

                      <CardTile>
                        <p className="wk-size-10 text-light wk-is-centered wk-journalcard-count" onClick={() => handleSubscriberClick(subscriber, journalId, journalName)}>
                          {subscriber.TotalCountOnline +
                            subscriber.TotalCountPrint}
                        </p>
                      </CardTile>
                      <CardBody>
                        <div className="card-sub-count wk-journalcard-count">
                          <p>
                            Online: <span>{subscriber.TotalCountOnline}</span>
                          </p>

                          <p>
                            Print:{" "}
                            <span>{subscriber.TotalCountPrint || 0}</span>
                          </p>
                        </div>
                      </CardBody>
                    </div>
                  </CardContent>
                </div>
              </Card>
            </div>
          ))}
        </div>
      ) : (
        <p className="wk-weight-500 wk-text-muted-light">
          No subscriber data is available.
        </p>
      )}
    </>
  );
};

export default React.memo(SubscriberCards);
