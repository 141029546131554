import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tag, SelectField, Modal, ModalHeader, ModalBody } from "@wk/components-react16";
import MenuscriptTable from "../Components/MenuscriptTable";
import "../App.css";
import SubscriberCards from "../Components/SubscriberCard";
import ArticleRateTable from "./ArticleRateTable";
import { useDataContext } from "../utils/DataContext";
import axios from "axios";
import PageTitle from "./PageTitle";
import { editorJournalsAPI } from "../API/API";
import MemberCard from "../Components/MemberCard";
import { v4 as uuidv4 } from "uuid";

const Dashboard = (props) => {
  PageTitle("Dashboard");
  const { setListData = () => {} } = useDataContext() || {};
  const [isOpen, setIsOpen] = useState(true);
  const [listJournal, setListJournal] = useState([]);
  const currentYear = new Date().getFullYear();
  const [isJournalApiCalled, setIsJournalApiCalled] = useState(false);
  const [journalId, setJournalId] = useState(0);
  const [journalName, setJournalName] = useState("");
  const navigate = useNavigate();

  const handleCloseButton = () => {
    setIsOpen(false);
    sessionStorage.clear();
    navigate("/");
  };

  const handleJournalIdChange = (event) => {
    const selectedJournalId = parseInt(event.target.value, 10);
    setJournalId(selectedJournalId);

    // Find the selected journal and update the journal name
    const selectedJournal = listJournal.find(
      (journal) => journal.JournalId === selectedJournalId
    );
    if (selectedJournal) {
      setJournalName(selectedJournal.JournalName);
    } else {
      setJournalName("");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiDataJSON = sessionStorage.getItem("apiData");
        if (apiDataJSON === null) {
          throw new Error("apiData not found in sessionStorage");
        }
        const {
          Data: { UserId },
        } = JSON.parse(apiDataJSON);

        const response = await axios.get(
          `${editorJournalsAPI}?userId=${UserId}`
        );

        if (response.status === 200) {
          const {
            Data: { EditorJournalList },
          } = response.data;
          setListJournal(EditorJournalList);
          setListData(response.data);
          sessionStorage.setItem("journallist", JSON.stringify(response.data));
          setIsJournalApiCalled(true);

          // Set initial journal ID and name
          if (EditorJournalList.length > 0) {
            setJournalId(EditorJournalList[0].JournalId);
            setJournalName(EditorJournalList[0].JournalName);
          }
        }
      } catch (err) {
        if (
          err.response?.status === 404 &&
          err.response.data.Message === "No journals found for this User"
        ) {
          setIsJournalApiCalled(true);
        } else {
          console.error("Error fetching data:", err);
        }
      }
    };

    fetchData();
  }, [setListData]);

  return (
     
      <div className="wk-page-container wk-page-container-custom">
        <div className="wk-grid-stage-workflow-container">
          <div className="wk-grid">
            {isJournalApiCalled && (
              <>
                <div className="wk-column-8">
                  {listJournal && listJournal.length > 0 ? (
                    <h3 key={journalId}>
                      {journalName} for year: {currentYear}
                    </h3>
                  ) : (
                    <>
                      {isOpen && (
                        <Modal isOpen={isOpen}>
                          <ModalHeader
                            isFullscreen
                            onUserRequest={handleCloseButton}
                          >
                            No journal assigned
                          </ModalHeader>
                          <ModalBody descriptionId="modal-id-002">
                            <br />
                            There isn't a Journal associated with this account.
                            The moment you click the close button, you will be
                            logged out.
                          </ModalBody>
                        </Modal>
                      )}
                    </>
                  )}
                </div>

                <div className="wk-column-4">
                  <SelectField labelFor="select-id-5-1" label="Select Journal">
                    <select
                      id="journal-list"
                      name="story5-1"
                      value={journalId}
                      onChange={handleJournalIdChange}
                    >
                      {listJournal?.map((journal, index) => (
                          <option key={uuidv4()} value={journal.JournalId}>
                            {journal.JournalName}
                          </option>
                        ))}
                    </select>
                  </SelectField>
                </div>
              </>
            )}
          </div>

          <br />
          <div className="wk-grid">
            <div className="wk-column-7">
              <h3>Count of Subscribers</h3>
              <br />
              <SubscriberCards
                journalId={journalId}
                handleJournalIdChange={handleJournalIdChange}
                journalName={journalName}
              />
            </div>
            <div className="wk-column-5">
              <h3>Count of Members</h3>
              <br />
              <MemberCard
                handleJournalIdChange={handleJournalIdChange}
                journalId={journalId}
                journalName={journalName}
              />
            </div>
          </div>

          <br />
        </div>
        <br />
        <div className="manuscriptCard">
          <h3>Custom Manuscript rates</h3>
          <div>
            <Tag color="blue">
              <p>MS = Manuscript submission charges</p>
            </Tag>{" "}
            &nbsp;
            <Tag color="blue">APC = Article processing charges</Tag>
          </div>
          <br />
          <MenuscriptTable
            journalId={journalId}
            handleJournalIdChange={handleJournalIdChange}
          />
        </div>
        <br />
        <div className="mar-bottom">
          <h3>Article Rates</h3>
          <br />
          <ArticleRateTable
            journalId={journalId}
            handleJournalIdChange={handleJournalIdChange}
          />
        </div>
      </div>
    
  );
};

export default React.memo(Dashboard);
