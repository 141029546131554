import axios from "axios";
import * as Sentry from "@sentry/react";

const createRequestInterceptor = () => {
  let subScriptionKey;
  let subScriptionValue;
  let configStorage = sessionStorage.getItem("config");
  if (configStorage !== null) {
    configStorage = JSON.parse(configStorage);
    axios.defaults.baseURL = configStorage.base_url;
    subScriptionKey = configStorage ? configStorage["subscription_key"] : "";
    subScriptionValue = configStorage
      ? configStorage["subscription_value"]
      : "";
  }
  //let isRefreshing = false;
  //let failedRequestsQueue = [];
  axios.interceptors.request.use(
    async (config) => {
      let requestMessage = `Url: ${config.url}`;
      if (config.data)
        requestMessage += `, Data: ${JSON.stringify(config.data)}`;
      Sentry.setExtra("Payload", config.data);
      Sentry.captureMessage(`Request ${config.method} - ${requestMessage}`);
      const accessToken = sessionStorage.getItem("access_token");
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      config.headers[subScriptionKey] = subScriptionValue;
      return config;
    },
    (error) => Promise.reject(error)
  );

  // axios.interceptors.response.use(
  //     (response) => response,
  //     async (error) => {
  //         const originalRequest = error.config;

  //         if (error.response.status === 401 && !originalRequest._retry && !isRefreshing) {
  //             originalRequest._retry = true;
  //             isRefreshing = true;

  //             try {
  //                 const refreshToken = sessionStorage.getItem('refresh_token');
  //                 const refreshResponse = await axios.post('refresh', { refresh_token: refreshToken });

  //                 if (refreshResponse.status === 200) {
  //                     const newAccessToken = refreshResponse.data.AuthResponse.AccessToken;
  //                     axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;

  //                     sessionStorage.setItem('access_token', newAccessToken);

  //                     failedRequestsQueue.forEach((prom) => prom.resolve(axios(originalRequest)));
  //                     failedRequestsQueue = [];

  //                     return axios(originalRequest);
  //                 }
  //             } catch (refreshError) {
  //                 console.error("Refresh token request failed:", refreshError);
  //                 //sessionStorage.clear();
  //             } finally {
  //                 isRefreshing = false;
  //             }
  //         }

  //         if (error.response.status === 401) {
  //             console.error("User not authenticated:", error);
  //             //sessionStorage.clear();
  //         }

  //         return Promise.reject(error);
  //     }
  // );
};

createRequestInterceptor();
// export default {
//     get: axios.get,
//     post: axios.post,
//     put: axios.put,
//     delete: axios.delete,
//     patch: axios.patch,
//     //createRequestInterceptor: createRequestInterceptor,
// };
export { createRequestInterceptor };
