import { Outlet, Navigate } from "react-router-dom";
import React from "react";

const ProtectedRoutes = () => {
  const accessToken = sessionStorage.getItem("access_token");

  return accessToken ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
