import React, { useState, useEffect } from "react";
import { SelectField, ButtonField, Iconsvg } from "@wk/components-react16";
import { useDataContext } from "../utils/DataContext";
import { showNotification } from "../Components/NotificatonPopup/showNotification";
import axios from "axios"; 
import * as XLSX from "xlsx";
import { journalYear, journalVolume, journalIssue, memberReportApi } from "../API/API";
import PageTitle from "../Components/PageTitle";


const MemberReport = () => {
  PageTitle("Member Report");
  const [selectedJournal, setSelectedJournal] = useState(0);    
  const [selectedYear, setSelectedYear] = useState(""); 
  const [selectedYearlist, setSelectedYearlist] = useState(0);
  const [selectedVolumeList, setSelectedVolumeList] = useState(0);
  const [selectedVolume, setSelectedVolume] = useState(0); 
  const [selectedIssue, setSelectedIssue] = useState(0);
  const [selectedIssueList, setSelectedIssueList] = useState(0);
  //const [loading, setLoading] = useState(false);
  //const [setLoading] = useState(false); 
 
  //--------------Journallist change-------------------//
  const { listData = () => {} } = useDataContext() || {};
  const listJournal = listData?.Data?.EditorJournalList ?? [];
  
    const handleJournalChange = (e) => {      
      const selectedValue = e.target.value;
      setSelectedJournal(selectedValue);      
      if (selectedValue === "Choose an option") {
        //setLoading(false);
      }
    };

  //--------------YearList change-------------------//
  useEffect(() => {
    if (selectedJournal > 0) {
      (async () => {
        await fetchJournalYears(selectedJournal);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJournal]);  
  
  const fetchJournalYears = async (journalId) => {
    try {
      //setLoading(true);
      const response = await axios.get(
        `${journalYear}?id=${journalId}`
      );
      setSelectedYear(response.data);
      //setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      //setLoading(false);
    }
  }; 
  
  const yearList = selectedYear.Data;    

  const handleYearlistChange = (e) => {
    const selectedValue = e.target.value;    
    setSelectedYearlist(selectedValue);
    if (selectedValue === "Select Year") {
      //setShowBox(false);
    }
  }; 

  //--------------Volume change based on Journal and Year-------------------//

  const volumeList = selectedVolume.Data;

  useEffect(() => {
    if (selectedYearlist && selectedJournal) {
      (async () => {
        await fetchVolumeList(selectedJournal, selectedYearlist);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYearlist, selectedJournal]);
  
  const fetchVolumeList = async (journalId, year) => {
    try {
      const response = await axios.post(journalVolume, {
        journalId: journalId,
        year: year
      });
      setSelectedVolume(response.data);
      //setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      //setLoading(false);
    }
  };

  const handleVolumeListChange = (e) => {
    const selectedValue = e.target.value;    
    setSelectedVolumeList(selectedValue);
    if (selectedValue === "Select Volume") {
      //setShowBox(false);
    }
  };

  //--------------Issue change based on Journal, Year and volume-------------------//

  const issueList = selectedIssue.Data;

  useEffect(() => {
    if (selectedVolumeList && selectedYearlist && selectedJournal) {
      (async () => {
        await fetchIssueList(selectedJournal, selectedYearlist, selectedVolumeList);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVolumeList, selectedYearlist, selectedJournal]);

  const fetchIssueList = async (journalId, year, volume) => {
    try {
      const response = await axios.post(journalIssue, {
        journalId: journalId,
        year: year,
        JournalVolume: volume,
      });
       
      setSelectedIssue(response.data);
      //setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      //setLoading(false);
    }
  };

  const handleIssueListChange = (e) => {
    const selectedValue = e.target.value;    
    setSelectedIssueList(selectedValue);
    if (selectedValue === "Select Volume") {
      //setLoading(false);
    }
  };

  //-----------------Download--------------------//
  
  const handleDownloadClick = async () => {
    if (![selectedJournal, selectedYearlist, selectedVolumeList, selectedIssueList].every(Boolean)) {
      showNotification("error", "Please select all required details!");
      //setLoading(false);
      return;
    }
  
    try {
      const response = await axios.post(memberReportApi, {
        JournalId: parseInt(selectedJournal),
        Year: parseInt(selectedYearlist),
        JournalVolume: parseInt(selectedVolumeList),
        JournaIssue: parseInt(selectedIssueList)
      });
  
      const reportData = response?.data?.Data;
  
      if (reportData && reportData.length > 0) {
        const formattedData = reportData.map((item) => ({
          "JIDId": item.JIDId,
          "JournalIssues": item.JournalIssues,
          "UserType": item.UserType,
          "FullName": item.FullName,
          "Address": item.Address
        }));
  
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "MemberReport");
        XLSX.writeFile(wb, "MemberReport.xlsx");
        showNotification("success", "Dispatch report downloaded successfully.");
        //setLoading(false);
      } else {
        showNotification("warning", "No data found");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        showNotification("error", "Data is found..!");
      } else{
          showNotification("error", "Dispatch report download failed.");
        }
      
    } finally {
      //setLoading(false);
    }
  };
  

  
  return (
    <>
      <div className="wk-page-container wk-page-container-custom">
        <h1>Member Dispatch Report</h1>
        <hr className="wk-divider" />

        <div className="wk-grid-stage-workflow-container">
          <div className="wk-grid">
            <div className="wk-column-4">
              <SelectField
                label="Select Journal"
                labelFor="select-journal"
                size="small"
                indicator="required"
              >
                <select
                  id="select-journal"                  
                  value={selectedJournal}
                  onChange={handleJournalChange}>
                  <option>Choose an option</option>
                  {listJournal &&
                    listJournal.map((journal, index) => (
                      <option key={index} value={journal.JournalId}>
                        {journal.JournalName}
                      </option>
                    ))}
                </select>
              </SelectField>
            </div>
            <div className="wk-column-4">
              <SelectField
                label="Select Year"
                labelFor="select-year"
                size="small"
                indicator="required"
              >
                <select
                  id="select-year"
                  value={selectedYearlist}
                  onChange={handleYearlistChange}                                   
                >
                  <option value="">Choose an option</option>
                  {yearList &&
                    yearList.length > 0 &&
                    yearList.map((journal, index) => (
                      <option value={journal.Value} key={index}>
                        {journal.Text}
                      </option>
                    ))}                   
                </select>
              </SelectField>
            </div>                        
          </div>
          <div className="wk-grid">             
            <div className="wk-column-4">
              <SelectField
                label="Select Volume"
                labelFor="select-volume"
                size="small"
                indicator="required">
                <select
                  value={selectedVolumeList}
                  onChange={handleVolumeListChange}
                >
                  <option value="">Choose an option</option>  
                  {volumeList &&
                    volumeList.map((journal, index) => (
                      <option value={journal.Value} key={index}>
                        {journal.Text}
                      </option>
                    ))}                  
                </select>
              </SelectField>     
            </div>
            <div className="wk-column-4">
              <SelectField
                label="Select Issue"
                labelFor="select-issue"
                size="small"
                indicator="required">
                <select
                  value={selectedIssueList}
                  onChange={handleIssueListChange}
                >
                  <option value="">Choose an option</option>  
                  {issueList &&
                    issueList.map((journal, index) => (
                      <option value={journal.Value} key={index}>
                        {journal.Text}
                      </option>
                    ))}                  
                </select>
              </SelectField>     
            </div>
          </div>
          <div className="wk-grid">             
            <div className="wk-column-4">           
             <ButtonField size="small" iconPosition={"left"}>
                <button onClick={handleDownloadClick} style={{ marginRight: "5px" }} type="button">
                <Iconsvg name="download-line"></Iconsvg>
                  Download
                </button>
              </ButtonField>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(MemberReport);
