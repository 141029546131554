import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDataContext } from "../utils/DataContext";
import { loginHistoryAPI } from "../API/API";
import axios from "axios";
//custom imports
import {
  Banner,
  BannerBrandSuite,
  BannerBrandLogo,
  BannerActionBarItem,
  ButtonField,
  ButtonGroup,
  BannerBrandInline,
  Iconsvg,
  BannerRight,
  Navbar,
  NavbarItemDropdown,
  DropdownList,
  DropdownListAnchorItem,
  NavbarItemSubmenu,
  DropdownMoreButton,
  Dropdown,
} from "@wk/components-react16";

const Header = () => {
  const { listData = () => {} } = useDataContext() || {};
  const userData = listData?.Data ?? [];
  const [isMobile, setIsMobile] = useState(false);
  const accessToken = sessionStorage.getItem("access_token");
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = sessionStorage.getItem("access_token");
    if (!accessToken) {
      navigate("/");
    }
  }, [accessToken, navigate]);

  const handleLogout = async () => {
    try {
      // Retrieve 'apiData' from sessionStorage
      const storeApiData = sessionStorage.getItem("apiData");

      if (storeApiData) {
        // Parse the retrieved JSON string
        const parsedData = JSON.parse(storeApiData);

        // Safely access the UserId using optional chaining
        const userId = parsedData?.Data?.UserId;

        // Get the current time as logout time
        const logoutTime = new Date().toISOString();

        if (userId) {
          // Record logout history
          const logoutHistorySuccess = await handleLoginHistory(
            userId,
            logoutTime
          );

          if (!logoutHistorySuccess) {
            console.error("Failed to record logout history.");
          }
        } else {
          console.error("User ID not found in the parsed apiData.");
        }
      } else {
        console.error("No apiData found in sessionStorage.");
      }
    } catch (error) {
      console.error("Error handling logout:", error);
    } finally {
      sessionStorage.clear();
      navigate("/");
    }
  };

  const handleLoginHistory = async (userId, logoutTime) => {
    try {
      const requestBody = {
        UserId: userId,
        LoginTime: null,
        LogoutTime: logoutTime,
        ApplicationId: 3,
      };

      const response = await axios.post(loginHistoryAPI, requestBody);
      return response.data;
    } catch (error) {
      console.error("Error recording login history:", error);
      return false;
    }
  };

  const handleChangePwd = () => {
    navigate("/changePassword");
  };

  const redirectToRoute = () => {
    navigate("/dashboard");
  };

  const handleClickHelp = () => {
    navigate("/help");
  };

  const onItemClick = (urlpath) => {
    navigate(urlpath);
  };

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  return (
    <header>
      <Banner mode="research">
        <BannerBrandSuite
          href=""
          onBrandClick={redirectToRoute}
          slot="bannerBrandSuite"
        >
          <BannerBrandLogo slot="bannerBrandLogo" />
          <BannerBrandInline productName="Wolters Kluwer" slot="bannerBrand" />
        </BannerBrandSuite>
        {accessToken ? (
          <BannerRight slot="bannerRight">
            <ButtonGroup
              threshold={272}
              dropdownToggleMode="gray"
              mode="dynamic"
            >
              <BannerActionBarItem slot="buttonGroupItem">
                <ButtonField mode={"text"} iconPosition={"left"}>
                  <button type="button" onClick={handleClickHelp}>
                    <Iconsvg name="process-list"></Iconsvg>
                    Help
                  </button>
                </ButtonField>
              </BannerActionBarItem>
              <BannerActionBarItem slot="buttonGroupItem">
                <ButtonField mode={"text"} iconPosition={"left"}>
                  <button type="button">
                    <Iconsvg name="user"></Iconsvg>
                    {userData.UserName}
                  </button>
                </ButtonField>
              </BannerActionBarItem>
              <DropdownListAnchorItem slot="buttonGroupDropdownItem">
                <Iconsvg name="user"></Iconsvg>
                {userData.UserName}
              </DropdownListAnchorItem>
              <DropdownListAnchorItem
                slot="buttonGroupDropdownItem"
                onItemClick={() => handleChangePwd()}
              >
                <Iconsvg name="key"></Iconsvg>
                Change Password
              </DropdownListAnchorItem>
              <DropdownListAnchorItem
                slot="buttonGroupDropdownItem"
                onItemClick={() => handleLogout()}
              >
                <Iconsvg name="logout"></Iconsvg>
                Logout
              </DropdownListAnchorItem>
            </ButtonGroup>

            {!isMobile && (
              <>
                <DropdownMoreButton
                  mode="grey"
                  buttonId="button1"
                ></DropdownMoreButton>
                <Dropdown
                  targetSelector="#button1"
                  menuMode="flyOut"
                  srLabelledby="button1"
                >
                  <DropdownList menuMode="flyOut">
                    <DropdownListAnchorItem
                      onItemClick={() => handleChangePwd()}
                      slot="dropdownListItem"
                    >
                      <Iconsvg name="key"></Iconsvg>
                      Change Password
                    </DropdownListAnchorItem>
                    <DropdownListAnchorItem
                      onItemClick={() => handleLogout()}
                      slot="dropdownListItem"
                    >
                      <Iconsvg name="logout"></Iconsvg>
                      Logout
                    </DropdownListAnchorItem>
                  </DropdownList>
                </Dropdown>
              </>
            )}
          </BannerRight>
        ) : null}
      </Banner>
      {accessToken ? (
        <Navbar isClassicStyle={true}>
          <NavbarItemDropdown
            shouldShowToggleIcon
            iconPosition="right"
            slot="navbarItemA11y"
          >
            <span slot="navbarItemDropdownToggle">Admin</span>
            <DropdownList menuMode={"flyOut"} slot="navbarItemDropdownMenu">
              <DropdownListAnchorItem
                onItemClick={() => onItemClick("/")}
                slot="dropdownListItem"
              >
                Dashboard
              </DropdownListAnchorItem>
              <DropdownListAnchorItem
                onItemClick={() => onItemClick("custom-manuscript-rates")}
                slot="dropdownListItem"
              >
                Custom Manuscript Rate
              </DropdownListAnchorItem>
              <DropdownListAnchorItem
                Journal
                Member
                Update
                onItemClick={() => onItemClick("journal-member-update")}
                slot="dropdownListItem"
              >
                Journal Member Update
              </DropdownListAnchorItem>
              <DropdownListAnchorItem
                onItemClick={() => onItemClick("journal-member-upload")}
                slot="dropdownListItem"
              >
                Journal Member Upload
              </DropdownListAnchorItem>
              <DropdownListAnchorItem
                onItemClick={() => onItemClick("payments")}
                slot="dropdownListItem"
              >
                Payment Received Report
              </DropdownListAnchorItem>
              <DropdownListAnchorItem
                onItemClick={() => onItemClick("memberreport")}
                slot="dropdownListItem"
              >
                Member Dispatch Report
                {/* <Link to="/payments">Payment Received Report</Link> */}
              </DropdownListAnchorItem>
            </DropdownList>
          </NavbarItemDropdown>
          <NavbarItemSubmenu slot="navbarDropdownItemA11y">
            <span slot="navbarItemSubmenuToggle">Admin</span>
            <DropdownListAnchorItem
              onItemClick={() => onItemClick("/")}
              slot="navbarItemDropdownSubmenuItem"
            >
              Dashboard
            </DropdownListAnchorItem>
            <DropdownListAnchorItem
              onItemClick={() => onItemClick("custom-manuscript-rates")}
              slot="navbarItemDropdownSubmenuItem"
            >
              Custom Manuscript Rate
            </DropdownListAnchorItem>
            <DropdownListAnchorItem
              onItemClick={() => onItemClick("journal-member-update")}
              slot="navbarItemDropdownSubmenuItem"
            >
              Journal Member Update
            </DropdownListAnchorItem>
            <DropdownListAnchorItem
              onItemClick={() => onItemClick("journal-member-upload")}
              slot="navbarItemDropdownSubmenuItem"
            >
              Journal Member Upload
            </DropdownListAnchorItem>
            <DropdownListAnchorItem
              onItemClick={() => onItemClick("payments")}
              slot="navbarItemDropdownSubmenuItem"
            >
              Payment Received Report
            </DropdownListAnchorItem>
            <DropdownListAnchorItem
              onItemClick={() => onItemClick("memberreport")}
              slot="navbarItemDropdownSubmenuItem"
            >
              Member Dispatch Report
              {/* <Link to="/payments">Payment Received Report</Link> */}
            </DropdownListAnchorItem>
          </NavbarItemSubmenu>
        </Navbar>
      ) : null}
    </header>
  );
};

export default React.memo(Header);
