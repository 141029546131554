import React, { useState, useEffect } from "react";
import { Card, CardContent, Tag } from "@wk/components-react16";
import axios from "axios";
//import { useNavigate } from "react-router-dom"; 
import { memberCountAPI } from "../API/API"; 
import { showNotification } from "../Components/NotificatonPopup/showNotification"; 
import PageTitle from "../Components/PageTitle";


const MemberCard = (props) => {  
  PageTitle("Member Card");
  const { journalId, journalName } = props;
  const [memberData, setMemberData] = useState({});
  const [loading, setLoading] = useState(true);
  //const navigate = useNavigate(); 
   
  useEffect(() => {
    const fetchMemberData = async () => {      
      
      try {
        setLoading(true);
        const response = await axios.get(
          `${memberCountAPI}?JournalId=${journalId}`
        );
        setMemberData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        setMemberData({ Data: [] });
      }
    };
    if (journalId > 0) {
      fetchMemberData(journalId);
    }
  }, [journalId]);

  const members = memberData.Data;  
    let categorizedMembers =
      members &&
      members.reduce((result, members) => {
        const { MemberType, TotalCount, Type } = members;        
        const category = `${MemberType}`;
        if (!result[category]) {
          result[category] = {
            MemberType,
            Type,
            TotalCount
            };
        }
         
        return result;
      }, {});
  
    if (
      categorizedMembers &&
      !Object.keys(categorizedMembers).includes("Complimentary Users")
    ) {
      const Complimentary = {
        MemberType: "Complimentary Users",
        TotalCount: 0,
        Type:"Members"
      };
      categorizedMembers = { ...categorizedMembers, Complimentary };
    }
    if (
      categorizedMembers &&
      !Object.keys(categorizedMembers).includes("Editorial Members")
    ) {
      const Editorial = {
        MemberType: "Editorial Members",
        TotalCount: 0,
        Type:"Members"
      };
      categorizedMembers = { ...categorizedMembers, Editorial };
    }
    if (
      categorizedMembers &&
      !Object.keys(categorizedMembers).includes("Members")
    ) {
      const Members = {
        MemberType: "Members",
        TotalCount: 0,
        Type:"Members"
      };
      categorizedMembers = { ...categorizedMembers, Members };
    }
  
  // const handleMemberClick = (members, journalId) => { 
  //   if (parseInt(members.TotalCount) > 0) {            
  //     // navigate(`/memberlist?Type=${members.MemberType}&UserType=${members.MemberType}&JournalId=${journalId}`); 
  //     window.open(`/memberlist?Type=Members&UserType=${members.MemberType}&JournalId=${journalId}` , '_blank');      
  //   }  
  //   else {      
  //     showNotification("error", "No Members found.");       
  //   } 
  // };

  const handleMemberClick = (members, journalId, journalName) => { 
    if (parseInt(members.TotalCount) > 0) {            
      localStorage.setItem("journalName", journalName);     
      window.open(`/memberlist?Type=Members&UserType=${members.MemberType}&JournalId=${journalId}`,
      );      
    } else {      
      showNotification("error", "No Members found.");       
    } 
  };

  
  return (
    <>
    {loading ? (
        <p className="wk-weight-500 wk-text-muted-light">Loading....</p>
      ) : Object.values(categorizedMembers) &&
        Object.values(categorizedMembers).length > 0 ? (
        <div className="wk-grid">
          {Object.values(categorizedMembers).map((member, index) => (
            <div className="wk-column-4 text-light" key={index}>
              <Card>  
                <div
                  className="JournalCard"
                  style={{
                    backgroundColor: ["#E5202E", "#EA8F00", "#474747"][index % 3],
                  }}
                >
                  <CardContent>
                    <div className="wk-journalcard-size">
                      <Tag>
                        <p className="wk-weight-500 wk-cards-toptitle">{member.MemberType}</p>
                      </Tag>
                      <p
    className="wk-size-10 text-light wk-is-centered wk-journalcard-count"
    onClick={() =>
      handleMemberClick(member, journalId,journalName) 
    }
  >
    {member.TotalCount}
  </p>
                    </div>
                  </CardContent>
                </div>
              </Card>
            </div>
          ))}
        </div>
        ) : (
          <p className="wk-weight-500 wk-text-muted-light">
            No subscriber data is available.
          </p>
        )}
    </>
  );
};

export default React.memo(MemberCard);