import { Iconsvg } from "@wk/components-react16";
import React from "react";

const AccessDenied = () => {
  return (
    <div className="content  wk-notification-warning wk-margins wk-is-centered">
      <Iconsvg name="caution"></Iconsvg>
      <h1 className="wk-margins-off wk-weight-500">401 Error</h1>
      <h2>Access Denied</h2>
      <p className="wk-text-muted-light">
        You do not have permmission to access this page
        <br /> Please contact system administrator.
      </p>
    </div>
  );
};

export default AccessDenied;
