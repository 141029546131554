import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import PageTitle from "../Components/PageTitle";
import axios from "axios";
import {
  InputField,
  Iconsvg,
  SelectField,
  ButtonField,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  ButtonGroupItem,
} from "@wk/components-react16";
import { showNotification } from "../Components/NotificatonPopup/showNotification";
import { useDataContext } from "../utils/DataContext";
import {
  CustomArticleTypeList,
  selectCustomTable,
  createCustomManuscript,
  updateCustomManuscript,
  deleteCustomManuscript,
  validateManuscriptID,
} from "../API/API";

const CustomManuscript = () => {
  PageTitle("Custom Manuscript Rates");
  const [, setManuscriptData] = useState([]);
  const [showBox, setShowBox] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [articleType, setArticleType] = useState({});
  const [selectedJournal, setSelectedJournal] = useState(0);
  const [selectedArticle, setSelectedArticle] = useState(0);
  const [selectId, setSelectedId] = useState(0);
  const [manuscriptTable, setManuscriptTable] = useState({});
  const [deletId, setDeleteId] = useState(0);
  // State variables to store input field values
  const [manuscriptId, setManuscriptId] = useState("");
  const [msInr, setMsInr] = useState(null);
  const [msUsd, setMsUsd] = useState(null);
  const [apcInr, setApcInr] = useState(null);
  const [apcUsd, setApcUsd] = useState(null);
  const [manuscriptIdError, setManuscriptIdError] = useState("");
  const [msInrError, setMsInrError] = useState("");
  const [msUsdError, setMsUsdError] = useState("");
  const [apcInrError, setApcInrError] = useState("");
  const [apcUsdError, setApcUsdError] = useState("");
  const { listData = () => {} } = useDataContext() || {};
  const listJournal = listData?.Data?.EditorJournalList ?? [];

  //===Validate Manuscript ID====
  const [journalCode, setJournalCode] = useState("");
  const currentYear = new Date().getFullYear() % 100;
  const defaultXX = "XX";
  const [placeholder, setPlaceholder] = useState(
    `JournalCode_${defaultXX}_${currentYear}`
  );
  const [alertShown, setAlertShown] = useState(false); // Track if alert has been shown

  //=========validation================================
  const handleUserRequest = (origin) => {
    if (
      origin === "header" ||
      origin === "footer" ||
      origin === "footer save button"
    ) {
      setIsOpen(false);
      setIsOpenDelete(false);
    }
  };
  const handleSelectClick = () => {
    if (!+selectedJournal || !+selectedArticle) {
      showNotification("error", "Please select required details!");
      return;
    }
    setShowBox(true);
  };

  const handleInputChange = (e, field) => {
    const inputValue = e.target.value;
    let formattedValue = inputValue;
    if (field !== "manuscriptId") {
      formattedValue = inputValue.replace(/(\.\d\d)\d+$/, "$1");
    }

    switch (field) {
      case "manuscriptId":
        setManuscriptId(formattedValue);
        setAlertShown(false); // Reset alertShown when the input changes
        setManuscriptIdError(""); // Clear error message when input changes
        break;
      case "msInr":
        setMsInr(formattedValue);
        setMsInrError("");
        break;
      case "msUsd":
        setMsUsd(formattedValue);
        setMsUsdError("");
        break;
      case "apcInr":
        setApcInr(formattedValue);
        setApcInrError("");
        break;
      case "apcUsd":
        setApcUsd(formattedValue);
        setApcUsdError("");
        break;
      default:
        break;
    }
  };

  // Fetch journal code based on selected journal
  const fetchJournalCode = async (journalName) => {
    try {
      const response = await axios.get(validateManuscriptID);
      const data = response.data.Data;
      const journal = data.find((item) => item.Text === journalName);
      if (journal) {
        const fetchedJournalCode = journal.Code || "";
        setJournalCode(fetchedJournalCode);
        const newPlaceholder = fetchedJournalCode
          ? `${fetchedJournalCode}_${defaultXX}_${currentYear}`
          : `JournalCode_${defaultXX}_${currentYear}`;
        setPlaceholder(newPlaceholder);
      }
    } catch (error) {
      console.error("Error fetching Manuscript Codes:", error);
    }
  };

  const isValidXX = (xx) => /^\d+$/.test(xx);
  const isValidYear = (year) => {
    const yearInt = parseInt(year, 10);
    return !isNaN(yearInt) && yearInt <= currentYear;
  };

  const handleBlur = () => {
    if (!manuscriptId) {
      setManuscriptIdError("Manuscript ID is required");
      return;
    }

    if (journalCode) {
      const parts = manuscriptId.split("_");
      const isPartialValid = parts.length !== 3;
      const isInvalid = validateManuscriptId(parts);

      if (isPartialValid) {
        showAlert("The Manuscript id is Invalid");
      } else if (isInvalid) {
        if (!alertShown) {
          showAlert("The Manuscript id is Invalid");
          setAlertShown(true);
        }
      } else {
        setAlertShown(false);
      }
    }
  };

  const validateManuscriptId = (parts) => {
    return (
      parts[0] !== journalCode || !isValidXX(parts[1]) || !isValidYear(parts[2])
    );
  };

  const showAlert = (message) => {
    setTimeout(() => {
      alert(message);
    }, 0);
  };

  //===============================articleList======================================
  const handleJournalChange = (e) => {
    setShowBox(false);
    const selectedValue = e.target.value;
    setSelectedJournal(selectedValue);
    setManuscriptTable("");

    if (selectedValue === "Choose an option") {
      setShowBox(false);
      setJournalCode("");
    } else {
      const selectedJournalName = listJournal.find(
        (journal) => journal.JournalId === parseInt(selectedValue)
      )?.JournalName;
      fetchJournalCode(selectedJournalName);
    }
  };
  useEffect(() => {
    if (selectedJournal > 0) {
      (async () => {
        await handleArticleTypeList(selectedJournal);
      })();
    }
  }, [selectedJournal]);
  const handleArticleTypeList = async (journalId) => {
    try {
      setLoading(true);
      const response = await axios.post(CustomArticleTypeList, {
        journalId: journalId,
      });
      setArticleType(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const articleList = articleType.Data;

  //=======================custom manuscript table on select button click=============================
  const handleArticleChange = (e) => {
    const selectedValue = e.target.value;
    setManuscriptTable("");
    setSelectedArticle(selectedValue);
    if (selectedValue === "Select Article Type") {
      setShowBox(false);
    }
  };

  useEffect(() => {
    if (selectedArticle > 0 && selectedJournal > 0) {
      setManuscriptTable([]);
      setShowBox(false);
      handleCustomTable(selectedArticle[0], selectedJournal[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArticle, selectedJournal]);

  const handleCustomTable = async () => {
    setLoading(true);
    try {
      const response = await axios.post(selectCustomTable, {
        JournalId: selectedJournal,
        ArticleTypeId: selectedArticle,
      });
      setManuscriptTable(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const manuscriptList = manuscriptTable.Data;

  //======================================create manuscript============================================
  const handlecreateModalOpen = () => {
    setIsOpen(true);
    setApcInr("");
    setApcUsd("");
    setManuscriptId("");
    setMsUsd("");
    setMsInr("");
    setManuscriptIdError("");
    setMsInrError("");
    setMsUsdError("");
    setApcInrError("");
    setApcUsdError("");
  };

  const emptyError = () => {
    setManuscriptIdError("");
    setMsInrError("");
    setMsUsdError("");
    setApcInrError("");
    setApcUsdError("");
  };
  const validateFields = () => {
    let isValid = true;

    if (!manuscriptId) {
      setManuscriptIdError("Manuscript ID is required");
      isValid = false;
    } else {
      setManuscriptIdError("");
    }

    if (!msInr) {
      setMsInrError("MS INR is required");
      isValid = false;
    } else {
      setMsInrError("");
    }

    if (!msUsd) {
      setMsUsdError("MS USD is required");
      isValid = false;
    } else {
      setMsUsdError("");
    }

    if (!apcInr) {
      setApcInrError("APC INR is required");
      isValid = false;
    } else {
      setApcInrError("");
    }

    if (!apcUsd) {
      setApcUsdError("APC USD is required");
      isValid = false;
    } else {
      setApcUsdError("");
    }

    return isValid;
  };

  const handleCreateManuscripts = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }
    setLoading(true);
    setIsOpen(false);
    const manuscriptdata = {
      ArticleTypeId: selectedArticle,
      JournalId: selectedJournal,
      ManuscriptId: manuscriptId,
      MsInr: msInr,
      MsUsd: msUsd,
      ApcInr: apcInr,
      ApcUsd: apcUsd,
      Active: true,
    };
    try {
      const response = await axios.post(createCustomManuscript, manuscriptdata);

      setManuscriptData((prevData) => [...prevData, response.data]);
      showNotification("success", "Custom Manuscript rate added successfully!");
      handleCustomTable();
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 409) {
        showNotification("warning", "Custom manuscript rate already exists!");
      } else {
        showNotification("error", "Error while adding Custom manuscript rate!");
      }
    }
    setIsOpen(false);
  };

  //===================update manuscript==============================
  const handleIdChange = (selectId) => {
    setSelectedId(selectId);
    const result = manuscriptTable.Data.filter((x) => x.Id === selectId)[0];
    setManuscriptId(result.ManuscriptId);
    setApcInr(result.ApcInr);
    setApcUsd(result.ApcUsd);
    setMsInr(result.MsInr);
    setMsUsd(result.MsUsd);
    setManuscriptId(result.ManuscriptId);
    setApcInr(result.ApcInr);
  };
  const handleUpdateModalOpen = () => {
    setIsOpenUpdate(true);
    emptyError();
  };
  const handleUpdateManuscript = async () => {
    emptyError();
    if (!validateFields()) {
      return;
    }
    setLoading(true);
    setIsOpenUpdate(false);
    if (!selectId || !selectedArticle || !selectedJournal) {
      console.error("Required values are missing for manuscript update.");
      return;
    }
    if (!validateFields()) {
      return;
    }
    const manuscriptData = {
      Id: selectId,
      ArticleTypeId: selectedArticle,
      JournalId: selectedJournal,
      ManuscriptId: manuscriptId,
      MsInr: msInr,
      MsUsd: msUsd,
      ApcInr: apcInr,
      ApcUsd: apcUsd,
      Active: true,
    };
    try {
      const response = await axios.post(updateCustomManuscript, manuscriptData);
      setManuscriptData((prevData) => [...prevData, response.data]);
      handleCustomTable();
      showNotification(
        "success",
        "Custom Manuscript rate updated successfully!"
      );
    } catch (error) {
      setIsOpenUpdate(false);
      showNotification("error", "Error while updating Custom manuscript rate!");
    }
    setIsOpenUpdate(false);
  };

  const handleDeleteManuscript = async (manuscriptId) => {
    setIsOpenDelete(false);
    setLoading(true);
    try {
      const response = await axios.post(deleteCustomManuscript, {
        Id: manuscriptId,
      });
      if (
        response.status === 200 &&
        response.data.Message === "This rate already used."
      ) {
        setLoading(false);
        showNotification(
          "warning",
          "This rate already used. Hence cannot be deleted."
        );
      } else if (response.status === 200) {
        setManuscriptTable((prevTable) => ({
          ...prevTable,
          Data: prevTable.Data.filter((rate) => rate.Id !== manuscriptId),
        }));
        setLoading(false);
        showNotification(
          "success",
          "Custom Manuscript rate deleted successfully!"
        );
        handleCustomTable();
      } else {
        setLoading(false);
        showNotification(
          "error",
          "Error while deleting Custom manuscript rate!"
        );
      }
    } catch (error) {
      setLoading(false);
      showNotification("error", "Error while deleting Custom manuscript rate!");
      console.error("Error deleting manuscript:", error);
    }
  };

  return (
    <div className="wk-page-container wk-page-container-custom">
      <h1>Manage Custom Manuscript Rates</h1>
      <hr className="wk-divider" />

      <div className="wk-grid-stage-workflow-container">
        <div className="wk-grid">
          <div className="wk-column-4">
            <SelectField
              label="Select Journal"
              labelFor="select-id-7-1"
              size="small"
              indicator="required"
            >
              <select
                id="select-id-7-1"
                name="story7-1"
                value={selectedJournal}
                onChange={handleJournalChange}
              >
                <option>Choose an option</option>
                {listJournal?.map((journal) => (
                  <option key={journal.JournalId} value={journal.JournalId}>
                    {journal.JournalName}
                  </option>
                ))}
              </select>
            </SelectField>
          </div>
          <div className="wk-column-4 wk-column-12-xs">
            <SelectField
              label="Select Article Type"
              labelFor="select-id-7-1"
              size="small"
              indicator="required"
            >
              <select
                id="select-id-7-1"
                name="story7-1"
                value={selectedArticle}
                onChange={handleArticleChange}
              >
                <option>Select Article Type</option>
                {articleList &&
                  articleList.length > 0 &&
                  articleList.map((article) => (
                    <option value={article.Value} key={uuidv4()}>
                      {article.Text}
                    </option>
                  ))}
              </select>
            </SelectField>
          </div>
          <div className="wk-column-4 wk-column-6-xs margin-22">
            <ButtonField size="small">
              <button onClick={handleSelectClick} type="button">
                Select
              </button>
            </ButtonField>
          </div>
        </div>
      </div>
      <br />
      {showBox && (
        <div
          className="wk-grid-stage-workflow-container"
          data-testid="container-id"
        >
          <ButtonField>
            <button
              // style={{ padding: "10px 23px" }}
              type="button"
              onClick={handlecreateModalOpen}
            >
              Add Manuscript Rate
            </button>
          </ButtonField>
          <br />
          <br />
          <div className="wk-table-permit-overflow">
            <table className="wk-table wk-table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Manuscript ID</th>
                  <th scope="col">MS(&#8377;)</th>
                  <th scope="col">MS(&#36;)</th>
                  <th scope="col">APC(&#8377;)</th>
                  <th scope="col">APC(&#36;)</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <tr>
                    <td align="center" colSpan={8}>
                      <p
                        className="wk-weight-500 wk-text-muted-light"
                        data-testid="testloading"
                      >
                        Loading....
                      </p>
                    </td>
                  </tr>
                ) : manuscriptList && manuscriptList.length > 0 ? (
                  manuscriptList.map((rate) => (
                    <tr key={rate.Id}>
                      <td>{rate.Id}</td>
                      <td>{rate.ManuscriptId}</td>
                      <td>{rate.MsInr}</td>
                      <td>{rate.MsUsd}</td>
                      <td>{rate.ApcInr}</td>
                      <td>{rate.ApcUsd}</td>
                      <td>
                        <div className="tableAction">
                          <ButtonField mode={"text"}>
                            <button
                              className="table-button-np"
                              type="button"
                              // style={{ padding: "0px" }}
                              onClick={() => {
                                handleIdChange(rate.Id);
                                handleUpdateModalOpen();
                              }}
                            >
                              <Iconsvg name="pencil" caption="Update"></Iconsvg>
                            </button>
                          </ButtonField>
                          <ButtonField mode={"text"} isDanger>
                            <button
                              type="button"
                              onClick={() => {
                                setDeleteId(rate.Id);
                                setIsOpenDelete(true);
                              }}
                            >
                              <Iconsvg name="trash" caption="Remove"></Iconsvg>
                            </button>
                          </ButtonField>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td align="center" colSpan={8}>
                      <p className="wk-weight-500 wk-text-muted-light">
                        No records found!
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* --------------------------------------DELETE MANUSCRIPT--------------------------------- */}

      <div>
        {isOpenDelete && (
          <Modal
            isOpen={isOpenDelete}
            onUserRequest={() => setIsOpenDelete(false)}
          >
            <ModalHeader
              isFullscreen
              onUserRequest={() => setIsOpenDelete(false)}
            >
              Confirm delete
            </ModalHeader>
            <ModalBody descriptionId="modal-id-002">
              <p>Do you really want to delete this record?</p>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup mode="static">
                <ButtonGroupItem slot="buttonGroupItem">
                  <ButtonField mode={"text"}>
                    <button
                      type="button"
                      id="cancel"
                      onClick={() => setIsOpenDelete(false)}
                    >
                      Cancel
                    </button>
                  </ButtonField>
                </ButtonGroupItem>
                <ButtonGroupItem slot="buttonGroupItem">
                  {deletId !== null && (
                    <ButtonField>
                      <button
                        type="button"
                        id="save"
                        onClick={() => handleDeleteManuscript(deletId)}
                      >
                        Save
                      </button>
                    </ButtonField>
                  )}
                </ButtonGroupItem>
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        )}
      </div>

      {/* --------------------------UPDATE MANUSCRIPT RATE MODAL-------------------------------- */}
      <div className="custom-modal">
        {isOpenUpdate && (
          <Modal
            isOpen={isOpenUpdate}
            onUserRequest={() => setIsOpenUpdate(false)}
            mobileMode="default"
            width="840px"
          >
            <ModalHeader
              isFullscreen
              onUserRequest={() => setIsOpenUpdate(false)}
            >
              Update Custom Manuscript Rates
            </ModalHeader>
            <ModalBody descriptionId="modal-id-002">
              <div className="wk-grid-stage-workflow-container">
                <div className="wk-grid">
                  <div className="wk-column-6">
                    <InputField
                      label="Manuscript Id"
                      labelFor="manuscript-id"
                      size="small"
                      indicator="required"
                    >
                      <input
                        type="text"
                        id="manuscriptId"
                        name="manuscriptId"
                        placeholder={placeholder}
                        value={manuscriptId}
                        onChange={(e) => handleInputChange(e, "manuscriptId")}
                        onBlur={handleBlur}
                      />
                      <span className="wk-primary-red">
                        {manuscriptIdError}
                      </span>
                    </InputField>
                  </div>
                </div>
                <div className="wk-grid">
                  <div className="wk-column-6">
                    <InputField
                      label="MS INR"
                      labelFor="ms-inr"
                      size="small"
                      indicator="required"
                    >
                      <input
                        type="number"
                        id="ms-inr"
                        min="0"
                        name="ms-inr"
                        value={msInr}
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleInputChange(e, "msInr")}
                      />
                      <span className="wk-primary-red">{msInrError}</span>
                    </InputField>
                  </div>
                  <div className="wk-column-6">
                    <InputField
                      label="MS USD"
                      labelFor="ms-usd"
                      size="small"
                      indicator="required"
                    >
                      <input
                        type="number"
                        id="ms-usd"
                        name="ms-usd"
                        min="0"
                        value={msUsd}
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleInputChange(e, "msUsd")}
                      />

                      <span className="wk-primary-red">{msUsdError}</span>
                    </InputField>
                  </div>
                </div>
                <div className="wk-grid">
                  <div className="wk-column-6">
                    <InputField
                      label="APC INR"
                      labelFor="apc-inr"
                      size="small"
                      indicator="required"
                    >
                      <input
                        type="number"
                        id="apc-inr"
                        name="apc-inr"
                        min="0"
                        value={apcInr}
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleInputChange(e, "apcInr")}
                      />
                      <span className="wk-primary-red">{apcInrError}</span>
                    </InputField>
                  </div>
                  <div className="wk-column-6">
                    <InputField
                      label="APC USD"
                      labelFor="apc-usd"
                      size="small"
                      indicator="required"
                    >
                      <input
                        type="number"
                        id="apc-usd"
                        name="apc-usd"
                        min="0"
                        value={apcUsd}
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleInputChange(e, "apcUsd")}
                      />
                      <span className="wk-primary-red">{apcUsdError}</span>
                    </InputField>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup mode="static">
                <ButtonGroupItem slot="buttonGroupItem">
                  <ButtonField mode={"text"}>
                    <button
                      type="button"
                      id="cancel"
                      onClick={() => setIsOpenUpdate(false)}
                    >
                      Cancel
                    </button>
                  </ButtonField>
                </ButtonGroupItem>
                <ButtonGroupItem slot="buttonGroupItem">
                  <ButtonField>
                    <button
                      type="button"
                      id="save"
                      onClick={handleUpdateManuscript}
                    >
                      Save
                    </button>
                  </ButtonField>
                </ButtonGroupItem>
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        )}
      </div>

      {/* ===========================Add custom manuscript rate Modal started================= */}
      <div className="custom-modal">
        {isOpen && (
          <Modal
            isOpen={isOpen}
            onUserRequest={(event) => handleUserRequest(event)}
            mobileMode="default"
            width="840px"
          >
            <ModalHeader
              isFullscreen
              onUserRequest={() => handleUserRequest("header")}
            >
              Add Custom Manuscript Rates
            </ModalHeader>
            <ModalBody descriptionId="modal-id-002">
              <div className="wk-grid-stage-workflow-container">
                <div className="wk-grid">
                  <div className="wk-column-6">
                    <InputField
                      label="Manuscript Id"
                      labelFor="manuscriptId"
                      size="small"
                      indicator="required"
                    >
                      <input
                        type="text"
                        id="manuscriptId"
                        name="manuscriptId"
                        placeholder={placeholder}
                        value={manuscriptId}
                        onChange={(e) => handleInputChange(e, "manuscriptId")}
                        onBlur={handleBlur}
                      />
                      <span className="wk-primary-red">
                        {manuscriptIdError}
                      </span>
                    </InputField>
                  </div>
                </div>

                <div className="wk-grid">
                  <div className="wk-column-6">
                    <InputField
                      label="MS INR"
                      labelFor="input-id-2-2"
                      size="small"
                      indicator="required"
                    >
                      <input
                        type="number"
                        id="ms-inr"
                        min="0"
                        name="ms-inr"
                        value={msInr}
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleInputChange(e, "msInr")}
                      />
                      <span className="wk-primary-red">{msInrError}</span>
                    </InputField>
                  </div>
                  <div className="wk-column-6">
                    <InputField
                      label="MS USD"
                      labelFor="ms-usd"
                      size="small"
                      indicator="required"
                    >
                      <input
                        type="number"
                        id="ms-usd"
                        name="ms-usd"
                        min="0"
                        value={msUsd}
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleInputChange(e, "msUsd")}
                      />
                      <span className="wk-primary-red">{msUsdError}</span>
                    </InputField>
                  </div>
                </div>
                <div className="wk-grid">
                  <div className="wk-column-6">
                    <InputField
                      label="APC INR"
                      labelFor="apc-inr"
                      size="small"
                      indicator="required"
                    >
                      <input
                        type="number"
                        id="apc-inr"
                        name="apc-inr"
                        min="0"
                        value={apcInr}
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleInputChange(e, "apcInr")}
                      />
                      <span className="wk-primary-red">{apcInrError}</span>
                    </InputField>
                  </div>
                  <div className="wk-column-6">
                    <InputField
                      label="APC USD"
                      labelFor="apc-usd"
                      size="small"
                      indicator="required"
                    >
                      <input
                        type="number"
                        id="apc-usd"
                        name="apc-usd"
                        min="0"
                        value={apcUsd}
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleInputChange(e, "apcUsd")}
                      />

                      <span className="wk-primary-red">{apcUsdError}</span>
                    </InputField>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup mode="static">
                <ButtonGroupItem slot="buttonGroupItem">
                  <ButtonField mode={"text"}>
                    <button
                      type="button"
                      id="cancel"
                      onClick={() => handleUserRequest("footer")}
                    >
                      Cancel
                    </button>
                  </ButtonField>
                </ButtonGroupItem>
                <ButtonGroupItem slot="buttonGroupItem">
                  <ButtonField>
                    <button
                      type="button"
                      id="save"
                      //onClick={() => handleUserRequest('footer save button')}
                      onClick={handleCreateManuscripts}
                    >
                      Save
                    </button>
                  </ButtonField>
                </ButtonGroupItem>
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default React.memo(CustomManuscript);
