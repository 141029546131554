import { Iconsvg } from "@wk/components-react16";
import React from "react";

const PageNotFound = () => {
  return (
    <div className="content  wk-notification-warning wk-margins wk-is-centered">
      <Iconsvg name="caution"></Iconsvg>
      <h1 className="wk-margins-off wk-weight-500">404 Error</h1>
      <h2>Page Not Found !</h2>
      <p className="wk-text-muted-light">
        We`&apos;`re sorry we couldn`&apos;`t find what you are looking for.
        <br /> Start exploring again, second time`&apos;`s a charm.
      </p>
    </div>
  );
};

export default PageNotFound;
