import { useState } from 'react';

export function useValidation() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [text, setText] = useState('');    
    const [numbers] = useState();

    

    const handleNumberInput = (event) => {
        const key = event.key;
        if (/[a-zA-Z\s]/.test(key)) {
            setText(text + key);
        } else if (key === 'Backspace') {
            setText(text.slice(0, -1));
        } else {
            event.preventDefault();
        }
    };
    const handleTextInput = (event) => {
        const key = event.key;
        if (/[a-zA-Z\s.()]/.test(key) || key === 'Backspace') {
            setText((prevText) => {
                if (key === 'Backspace') {
                    return prevText.slice(0, -1);
                } else {
                    return prevText + key;
                }
            });
        } else {
            event.preventDefault();
        }
    };
    const handleUsernameChange = (value) => {
        setUsername(value);
        if (!value) {
            setUsernameError('Username is required');
        } else {
            setUsernameError('');
        }
    };
    // const handleEmailChanges = (value) => {
    //     setEmail(value);
    //     if (!value && formSubmitted) {
    //       setEmailError('Email is required');
    //     } else if (!isValidEmail(value)) {
    //       setEmailError('Invalid email format');
    //     } else {
    //       setEmailError('');
    //     }
    //   };

    const handleEmailChange = (value) => {
        setEmail(value);
        if (!isValidEmail(value)) {
            setEmailError('Invalid email format');
        } else {
            setEmailError('');
        }
    };

    const isValidEmail = (email) => {
        if (email === '') {
            return true; // Return true for empty input
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    

    return {
        username,
        handleUsernameChange,
        usernameError,
        email,
        text,
        isValidEmail,
        handleNumberInput,
        handleEmailChange,
        emailError,
        numbers,
        handleTextInput,
        setEmailError
    
    };
}
