// paymentUtils.js

import * as XLSX from "xlsx";
import { showNotification } from "../Components/NotificatonPopup/showNotification";
import axios from "axios";

export const handleStartDateChange = (
  e,
  endDate,
  setStartDate,
  setPaymentData,
  setShowBox
) => {
  setPaymentData([]);
  setShowBox(false);
  const selectedStartDate = new Date(e.target.value);
  if (!endDate || selectedStartDate <= endDate) {
    setStartDate(selectedStartDate);
  }
};

export const handleEndDateChange = (
  e,
  startDate,
  setEndDate,
  setPaymentData,
  setShowBox
) => {
  setPaymentData([]);
  setShowBox(false);
  const selectedEndDate = new Date(e.target.value);
  if (!startDate || selectedEndDate >= startDate) {
    setEndDate(selectedEndDate);
  }
};

export const formatDate = (dateString) => {
  if (!dateString) {
    return "-";
  }
  const dateObject = new Date(dateString);
  if (isNaN(dateObject)) {
    return "-";
  }
  const day = dateObject.toLocaleDateString("en-US", { day: "2-digit" });
  const month = dateObject.toLocaleDateString("en-US", { month: "short" });
  const year = dateObject.toLocaleDateString("en-US", { year: "numeric" });
  const abbreviatedMonth = month.substring(0, 3);
  return `${day}-${abbreviatedMonth}-${year}`;
};

export const validateInput = (selectedJournalId, startDate, endDate) => {
  if (!+selectedJournalId && !startDate && !endDate) {
    showNotification("error", "Please select required details!");
    return false;
  }

  if (!+selectedJournalId) {
    showNotification("error", "Please select required Journal!");
    return false;
  }

  if (!startDate) {
    showNotification("error", "Please select a valid start date!");
    return false;
  }

  if (!endDate) {
    showNotification("error", "Please select a valid end date!");
    return false;
  }

  return true;
};

export const handleIdChange = (
  e,
  setPaymentData,
  setShowBox,
  setPage,
  setSelectedJournalId
) => {
  setPaymentData([]);
  setShowBox(false);
  setPage(1);
  const selectedValue = e.target.value;
  setSelectedJournalId(selectedValue);
  if (selectedValue === "Please select Journal") {
    setShowBox(false);
  }
};

export const exportToExcel = async (
  paymentData,
  formattedPaymentData,
  setLoading
) => {
  if (paymentData.length === 0) {
    showNotification("warning", "No data is available.");
    return;
  }
  try {
    setLoading(true);
    const ws = XLSX.utils.json_to_sheet(formattedPaymentData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Payments");
    XLSX.writeFile(wb, "payments.xlsx");
    setLoading(false);
    showNotification("success", "Payment report downloaded successfully.");
  } catch (err) {
    console.error(err);
    showNotification("error", "Payment report download failed.");
    setLoading(false);
  }
};

export const fetchData = async ({
  selectedJournalId,
  startDate,
  endDate,
  manuscriptId,
  paymentApi,
  setLoading,
  setShowBox,
  setPaymentData,
  setPage,
  setPageCount,
  pageSize,
}) => {
  if (!validateInput(selectedJournalId, startDate, endDate)) {
    return;
  }

  setLoading(true);
  setShowBox(true);

  try {
    const response = await axios.post(paymentApi, {
      JournalId: selectedJournalId,
      FromDate: startDate,
      ToDate: endDate,
      ManuscriptId: manuscriptId,
    });

    const totalUsers = response.data.Data.length;
    setPaymentData(response.data.Data);
    const calculatedPageCount = Math.ceil(totalUsers / pageSize);
    setPage((prevPage) => Math.min(prevPage, calculatedPageCount));
    setPageCount(calculatedPageCount);
  } catch (err) {
    console.error(err);
  } finally {
    setLoading(false);
  }
};
