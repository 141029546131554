import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonField, PasswordField, Iconlabel } from "@wk/components-react16";
import { showNotification } from "../Components/NotificatonPopup/showNotification";
import { changePasswordApi } from "../API/API";
import axios from "axios";
import PageTitle from "../Components/PageTitle";

const ChangePassword = () => {
  PageTitle("Change Password");
  const navigate = useNavigate();
  const [type1, setType1] = useState("password");
  const [type2, setType2] = useState("password");
  const [type3, setType3] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [errorOldPass] = useState(null);
  const [errorNewPass] = useState(null);
  const [errorConfirmPass] = useState(null);
  //===============handle input chane==========================
  ////Validaions
  const validateFields = () => {
    let isValid = true;

    if (!oldPassword) {
      setOldPasswordError("Old Password is required");
      isValid = false;
    } else {
      setOldPasswordError("");
    }
    if (!newPassword) {
      setNewPasswordError("New Password is required");
      isValid = false;
    } else {
      if (passwordRegex.test(newPassword) === false) {
        setNewPasswordError("New Password does not meet the criteria");
        isValid = false;
      } else {
        setNewPasswordError("");
      }
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
      isValid = false;
    } else {
      if (passwordRegex.test(confirmPassword) === false) {
        setConfirmPasswordError("Confirm Password does not meet the criteria");
        isValid = false;
      } else {
        if (newPassword !== confirmPassword) {
          setConfirmPasswordError(
            "New Password and Confirm Password should match"
          );
          isValid = false;
        } else {
          setConfirmPasswordError("");
        }
      }
    }
    return isValid;
  };

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,15}$/;

  ////Validaions

  ////API Call

  const userID = JSON.parse(sessionStorage.getItem("apiData")).Data.UserId;
  const changePWD = async (e) => {
    if (!validateFields()) {
      return;
    }

    try {
      const response = await axios.post(changePasswordApi, {
        OldPassword: oldPassword,
        NewPassword: newPassword,
        ConfirmPassword: confirmPassword,
        UserId: userID,
      });

      if (response.status === 200) {
        if (
          response.data.Message === "Password has been updated successfully!"
        ) {
          setIsLoading(false);
          showNotification("success", response.data.Message);
          resetFormFields();

          setTimeout(() => {
            showNotification(
              "success",
              "Your session time out. Please login with the new credentials"
            );
            sessionStorage.clear();
            navigate("/");
          }, 4000);
        } else {
          setIsLoading(false);
          showNotification("error", response.data.Message);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        showNotification("error", "Account not found");
      } else if (
        error?.response?.status === 401 &&
        error?.response?.data.Message === "Unauthorized"
      ) {
        showNotification(
          "error",
          "Invalid credentials. Please check your email and password."
        );
      } else if (error?.response?.status === 500) {
        showNotification(
          "error",
          "Internal server error. Please try again later."
        );
      } else {
        showNotification(
          "error",
          "An error occurred while trying to change password."
        );
      }
      setIsLoading(false);
    }
  };
  ////API Call
  const handleModeChange1 = () => {
    setType1((prevType) => (prevType === "password" ? "text" : "password"));
  };
  const handleModeChange2 = () => {
    setType2((prevType) => (prevType === "password" ? "text" : "password"));
  };
  const handleModeChange3 = () => {
    setType3((prevType) => (prevType === "password" ? "text" : "password"));
  };
  const resetFormFields = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };
  const backToDashboard = () => {
    navigate("/dashboard");
  };
  return (
    <div className="wk-page-container wk-page-container-custom login-contianer">
      <div className="wk-grid-stage-workflow-container">
        <div className="wk-grid wk-grid-flex">
          <div className="wk-column-12">
            <form className="changePassword-form">
              <div>
                Note: Password should contain atleast 12 characters, 1 upper
                case, 1 lower case, 1 special character and 1 digit
              </div>
              <br></br>
              <h1>Change Password</h1>
              <PasswordField
                labelFor="password-id-3-2"
                label="Old Password"
                size="small"
                mode="secondary"
                indicator="required"
                type={type1}
                status={
                  errorOldPass
                    ? "error"
                    : oldPassword.trim() !== ""
                    ? "success"
                    : ""
                }
                error={errorOldPass}
                onModeChange={handleModeChange1}
              >
                <input
                  type={type1}
                  placeholder="Password"
                  id="oldPassword"
                  name="oldPassword"
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                    setOldPasswordError("");
                  }}
                />
                <span className="wk-primary-red">{oldPasswordError}</span>
              </PasswordField>
              <PasswordField
                labelFor="password-id-3-2"
                label="New Password"
                size="small"
                mode="secondary"
                indicator="required"
                type={type2}
                status={
                  errorNewPass
                    ? "error"
                    : newPassword.trim() !== ""
                    ? "success"
                    : ""
                }
                error={errorNewPass}
                onModeChange={handleModeChange2}
              >
                <input
                  type={type2}
                  placeholder="Password"
                  id="newPassword"
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setNewPasswordError("");
                  }}
                />
                <span className="wk-primary-red">{newPasswordError}</span>
              </PasswordField>
              <PasswordField
                labelFor="password-id-3-2"
                label="Confirm Password"
                size="small"
                mode="secondary"
                indicator="required"
                type={type3}
                status={
                  errorConfirmPass
                    ? "error"
                    : confirmPassword.trim() !== ""
                    ? "success"
                    : ""
                }
                error={errorConfirmPass}
                onModeChange={handleModeChange3}
              >
                <input
                  type={type3}
                  placeholder="Password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordError("");
                  }}
                />
                <span className="wk-primary-red">{confirmPasswordError}</span>
              </PasswordField>
              <ButtonField size="small">
                <button
                  onClick={changePWD}
                  type="button"
                  style={{ marginRight: "5px" }}
                >
                  Change Password
                  {isLoading && (
                    <Iconlabel name="spinner-alt" isSpin isFill></Iconlabel>
                  )}
                </button>
              </ButtonField>
              <ButtonField size="small">
                <button onClick={backToDashboard} type="button">
                  Cancel
                </button>
              </ButtonField>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
