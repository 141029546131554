import { createRoot } from "react-dom/client";

let root;

export const displayReactElement = (
  reactElement,
  targetCssClassName = ".js-react-element-container",
  purgeState = true
) => {
  if (!reactElement?.$$typeof) {
    window.console.warn(
      "first parameter is required and should be jsx element"
    );

    return;
  }

  let renderTarget = document.querySelector(targetCssClassName);

  if (!renderTarget) {
    renderTarget = document.createElement("div");
    renderTarget.classList.add(targetCssClassName.replace(/^\./, ""));

    document.body.append(renderTarget);
  }

  // unmount root to get rid of memoized state from previous render
  if (purgeState && root) {
    root.unmount();
  }

  root = createRoot(renderTarget);
  root.render(reactElement);
};
