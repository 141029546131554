import React, { useState, useEffect } from "react";
import { manuscriptRateAPI } from "../API/API";
import axios from "axios";

const ManuscriptTable = (props) => {
  const journalId = props.journalId;
  const [manuscriptRates, setManuscriptRates] = useState({});
  const [loading, setLoading] = useState(true);
  const noOfRecords = 10;

  useEffect(() => {
    const fetchManuscriptData = async (journalId) => {
      try {
        setLoading(true);
        setManuscriptRates([]);
        const response = await axios.get(
          `${manuscriptRateAPI}?JournalId=${journalId}&noOfRecords=${noOfRecords}`
        );
        const data = response?.data;
        setManuscriptRates(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    if (journalId > 0) {
      fetchManuscriptData(journalId);
    }
   
  }, [journalId, noOfRecords]);

  let manuscriptRate = manuscriptRates.Data;

  return (
    <>
      <div className="wk-table-permit-overflow">
        <table className="wk-table wk-table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Manuscript ID</th>
              <th scope="col">MS(&#8377;)</th>
              <th scope="col">MS(&#36;)</th>
              <th scope="col">APC(&#8377;)</th>
              <th scope="col">APC(&#36;)</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td align="center" colSpan={7}>
                  <p className="wk-weight-500 wk-text-muted-light">
                    Loading....
                  </p>
                </td>
              </tr>
            ) : manuscriptRate && manuscriptRate.length > 0 ? (
              manuscriptRate.map((rate, index) => (
                <React.Fragment key={index}>
                  <tr key={rate.Id}>
                    <td>{index + 1}</td>
                    <td>{rate.ManuscriptId}</td>
                    <td>{rate.MsInr}</td>
                    <td>{rate.MsUsd}</td>
                    <td>{rate.ApcInr}</td>
                    <td>{rate.ApcUsd}</td>
                  </tr>
                  {/* ))} */}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td align="center" colSpan={7}>
                  <p className="wk-weight-500 wk-text-muted-light">
                    No records found!
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default React.memo(ManuscriptTable);
