
import React from "react";
import { Notification } from "@wk/components-react16/dist/notification/notification";
import "@wk/components-react16/dist/notification.css";

/* eslint-disable react/prop-types */
const NotificatonPopup = ({
  type,
  heading,
  children = "",
  mode = "toast",
  autoCloseTimeout = 0,
}) => (
  <div className="site-notification">
    <Notification
      type={type}
      heading={heading}
      mode={mode}
      autoCloseTimeout={autoCloseTimeout}
    >
      <span slot="notificationContent">{children}</span>
    </Notification>
  </div>
);

export default NotificatonPopup;
