//login api
export const loginAPI = "/login/subscription";

//Login History
export const loginHistoryAPI = "/login/AddUpdateLoginHistory";

//Dashboard Api's
export const manuscriptRateAPI = "/CustomManuscriptRate/FetchByJournalId";
export const articleCitationAPI = "/JournalArticleXref/FetchXrefByJournaId";
export const subscribersAPI = "/usersubscription/FetchSubscriberCount";
export const editorJournalsAPI = "/Journals/GetEditorJournals";
export const memberCountAPI = "/JournalMembers/FetchMemberCount";
export const subscriberMemberReportAPI =
  "/JournalMembers/FetchSubscriberMemberReport";

//custom manuscript API's
export const CustomArticleTypeList = "/ArticleType/SelectItem";
export const selectCustomTable = "/CustomManuscriptRate/FetchByArticleId";
export const createCustomManuscript = "/CustomManuscriptRate/Create";
export const updateCustomManuscript = "/CustomManuscriptRate/Update";
export const deleteCustomManuscript = "/CustomManuscriptRate/Delete";
export const validateManuscriptID = "Journals/SelectItemWithCode";
//Journal member update
export const searchNameMember = "/JournalMembers/FetchMemberByName";
export const filterMember = "/JournalMembers/FetchJournalMemberByFilter";
export const viewMember = "/JournalMembers/FetchUserById";
export const userTypeApi = "/UserTypes/FetchAll";
export const countryListApi = "/Country/FetchAll";
export const stateListApi = "/State/SelectItem";
export const addMemberApi = "/JournalMembers/Create";
export const updateMemberApi = "/JournalMembers/UpdateJournalMember";

//journal member upload
export const importJournalMembrer = "/JournalMembers/ImportJournalMembers";
export const fileUploadApi = "/JournalMembers/ImportJournalMemberList";
export const TitleApi = "/salutation/SelectItem";

//paymentApi
export const paymentApi = "/Payments/PaymentReceivedReport";

//MemberReport
export const journalYear = "/JournalIssueDispatch/GetJournalYearsByJournalId";
export const journalVolume = "/JournalIssueDispatch/GetJournalVolumes";
export const journalIssue = "/JournalIssueDispatch/GetJournalIssues";
export const memberReportApi = "/JournalIssueDispatch/GetDeliveryAddrReport_ED";
//changePassword Api
export const changePasswordApi = "/LogIn/changePassword";
