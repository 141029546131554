import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";

const DataContext = createContext();

export function useDataContext() {
  return useContext(DataContext);
}

// eslint-disable-next-line react/prop-types
export function DataProvider({ children }) {
  const storedData = sessionStorage.getItem("journallist");
  const [listData, setListData] = useState(
    storedData ? JSON.parse(storedData) : null
  );

  useEffect(() => {
    if (storedData) {
      setListData(JSON.parse(storedData));
    }
  }, [storedData]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({ listData, setListData }),
    [listData, setListData]
  );

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
}

// PropTypes validation
DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
