import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Iconsvg, ButtonField, Pagination } from "@wk/components-react16";
import { showNotification } from "../Components/NotificatonPopup/showNotification";
import axios from "axios";
import * as XLSX from "xlsx";
import { subscriberMemberReportAPI } from "../API/API";
import PageTitle from "../Components/PageTitle";

const MemberList = () => {
  PageTitle("Member List");
  const [searchParams] = useSearchParams();
  const journalId = searchParams.get("JournalId");
  const memberType = searchParams.get("UserType");
  const SubscriptionType = searchParams.get("UserType");
  const type = searchParams.get("Type");
  const [journalName, setJournalName] = useState("");
  const [memberData, setMemberData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  let tableContent;

  useEffect(() => {
    const storedJournalName = localStorage.getItem("journalName");
    if (storedJournalName) {
      setJournalName(storedJournalName);
    }
  }, []);

  const fetchMemberData = async () => {
    try {
      setLoading(true);

      let response;

      if (type === "Members") {
        response = await axios.get(
          `${subscriberMemberReportAPI}?Type=Members&UserType=${memberType}&JournalId=${journalId}`
        );
      } else if (type === "Subscribers") {
        response = await axios.get(
          `${subscriberMemberReportAPI}?Type=Subscribers&UserType=${SubscriptionType}&JournalId=${journalId}`
        );
      } else {
        showNotification("error", "Error fetching member data.");
      }

      const data = response.data.Data;
      setMemberData(data);
      const totalUsers = data.length;
      const calculatedPageCount = Math.ceil(totalUsers / pageSize);
      setPage((prevPage) => Math.min(prevPage, calculatedPageCount));
      setPageCount(calculatedPageCount);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      showNotification("error", "Error fetching member data.");
    }
  };

  useEffect(() => {
    fetchMemberData();
    // eslint-disable-next-line
  }, [journalId, memberType, SubscriptionType]);

  const exportToExcel = async () => {
    try {
      setLoading(true);
      const fileName = `Member_details_${getIncrementedNumber()}.xlsx`;
      const ws = XLSX.utils.json_to_sheet(memberData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Members_details");
      XLSX.writeFile(wb, fileName);
      setLoading(false);
      showNotification("success", "Member details downloaded successfully.");
    } catch (error) {
      console.error(error);
      showNotification("error", "Member details download failed.");
      setLoading(false);
    }
  };

  const getIncrementedNumber = () => {
    if (typeof window.incrementedNumber === "undefined") {
      window.incrementedNumber = 1;
    } else {
      window.incrementedNumber++;
    }
    return padNumber(window.incrementedNumber);
  };

  const padNumber = (num) => {
    return num < 10 ? `0${num}` : num;
  };

  const startIdx = (page - 1) * pageSize;
  const endIdx = startIdx + pageSize;
  const memberDataList = memberData.slice(startIdx, endIdx);

  if (loading) {
    tableContent = (
      <tr>
        <td align="center" colSpan="8">
          Loading...
        </td>
      </tr>
    );
  } else if (memberDataList.length > 0) {
    tableContent = memberDataList.map((member, index) => (
      <tr key={`${member.MembershipId}-${index}`}>
        <td>{index + 1}</td>
        <td>{member.MembershipId}</td>
        <td>{member.FullName}</td>
        <td>{member.UserAddress}</td>
        <td>{member.City}</td>
        <td>{member.Pin || "-"}</td>
        <td>{member.State}</td>
        <td>{member.Country}</td>
      </tr>
    ));
  } else {
    tableContent = (
      <tr>
        <td align="center" colSpan="8">
          No records found
        </td>
      </tr>
    );
  }

  return (     
    <div className="wk-page-container wk-page-container-custom">
        <div className="wk-grid-stage-workflow-container">
          <div className="wk-grid">
            <div className="wk-column-10">
              <h3>{journalName}</h3>
            </div>
            <div className="wk-column-2">
              <ButtonField size="small" iconPosition={"left"}>
                <button type="button" onClick={exportToExcel}>
                  <Iconsvg name="download-line" />
                  Export To Excel
                </button>
              </ButtonField>
            </div>
          </div>
          <hr className="wk-divider" />
          <div className="wk-table-permit-overflow">
            <table className="wk-table wk-table-striped wk-custom-memberlist">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">MembershipId</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">User Address</th>
                  <th scope="col">City</th>
                  <th scope="col">Pin</th>
                  <th scope="col">State</th>
                  <th scope="col">Country</th>
                </tr>
              </thead>
              <tbody>
                {tableContent}
              </tbody>
            </table>
          </div>
          <div style={{ marginBottom: "20px" }}>
            {pageCount > 1 && (
              <Pagination
                pageCount={pageCount}
                pageIndex={page}
                onOnNextArrowClick={() => setPage((prevPage) => prevPage + 1)}
                onOnPreviousArrowClick={() => setPage((prevPage) => prevPage - 1)}
                onOnPageClick={(page) => setPage(page)}
              />
            )}
          </div>
        </div>
      </div>     
  );
};

export default React.memo(MemberList);
