import React, { useState } from "react";
import {
  Pagination,
  SelectField,
  ButtonField,
  InputField,
  Iconsvg,
} from "@wk/components-react16";
import { paymentApi } from "../API/API";
import { useDataContext } from "../utils/DataContext";
import {
  handleEndDateChange,
  handleStartDateChange,
  exportToExcel,
  formatDate,
  handleIdChange,
  fetchData,
} from "../utils/paymentUtils"; // Adjust the path as necessary
import Table from "../Components/Table";

import PageTitle from "../Components/PageTitle";
const Payments = () => {
  PageTitle("Payment Received Report (MS, APC)");
  const [paymentData, setPaymentData] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [selectedJournalId, setSelectedJournalId] = useState(0);
  const { listData = () => {} } = useDataContext() || {};
  const [showBox, setShowBox] = useState(false);
  const [manuscriptId, setManuscriptId] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const listJournal = listData?.Data?.EditorJournalList ?? [];

  const columns = [
    { Header: "Manuscript ID", accessor: "ManuscriptId" },
    { Header: "Article Type", accessor: "ArticleType" },
    { Header: "Author", accessor: "Author" },
    { Header: "Payment For", accessor: "PaymentFor" },
    { Header: "Payment Currency", accessor: "PaymentCurrency" },
    { Header: "Payment Mode", accessor: "PaymentMode" },
    { Header: "Payment Date", accessor: "PaymentDate" },
    { Header: "InvoiceID", accessor: "InvoiceID" },
    { Header: "Invoice Amount", accessor: "InvoiceAmount" },
    { Header: "Invoice Date", accessor: "InvoiceDate" },
    { Header: "Transaction Ref No", accessor: "TransactionRefNo" },
  ];

  const today = new Date().toISOString().split("T")[0];
  const combinedMaxDate = endDate
    ? new Date(Math.min(new Date(today), endDate)).toISOString().split("T")[0]
    : today;

  const handleStartDateChangeWrapper = (e) => {
    handleStartDateChange(e, endDate, setStartDate, setPaymentData, setShowBox);
  };

  const handleEndDateChangeWrapper = (e) => {
    handleEndDateChange(e, startDate, setEndDate, setPaymentData, setShowBox);
  };

  const exportToExcelWrapper = () => {
    exportToExcel(paymentData, formattedPaymentData, setLoading);
  };

  const onIdChange = (e) => {
    handleIdChange(
      e,
      setPaymentData,
      setShowBox,
      setPage,
      setSelectedJournalId
    );
  };

  const onFetchData = () => {
    fetchData({
      selectedJournalId,
      startDate,
      endDate,
      manuscriptId,
      paymentApi,
      setLoading,
      setShowBox,
      setPaymentData,
      setPage,
      setPageCount,
      pageSize,
    });
  };

  const formattedPaymentData = paymentData.map((item) => {
    return {
      ...item,
      InvoiceDate: formatDate(item.InvoiceDate),
      PaymentDate: formatDate(item.PaymentDate),
    };
  });

  const startIdx = (page - 1) * pageSize;
  const endIdx = startIdx + pageSize;
  const paymentList = paymentData.slice(startIdx, endIdx);
  return (
    <div className="wk-page-container wk-page-container-custom">
      <h1>Payment Received Report (MS, APC)</h1>
      <hr className="wk-divider" />
      <div className="wk-grid-stage-workflow-container">
        <div className="wk-grid">
          <div className="wk-column-3">
            <SelectField
              labelFor="journal-list"
              label="Select Journal"
              size="small"
              indicator="required"
            >
              <select
                id="journal-list"
                name="story5-1"
                value={selectedJournalId}
                onChange={onIdChange}
              >
                <option>Please select Journal</option>
                {listJournal?.map((journal) => (
                  <option key={journal.JournalId} value={journal.JournalId}>
                    {journal.JournalName}
                  </option>
                ))}
              </select>
            </SelectField>
          </div>
          <div className="wk-column-3 ">
            <InputField
              label="Start Date"
              labelFor="fromDate"
              indicator="required"
              size="small"
            >
              <input
                type="date"
                id="fromDate"
                name="fromDate"
                placeholder="Search everywhere"
                value={startDate ? startDate.toISOString().split("T")[0] : ""}
                onChange={handleStartDateChangeWrapper}
                max={combinedMaxDate}
              />
            </InputField>
          </div>
          <div className="wk-column-3">
            <InputField
              label="End Date"
              labelFor="ToDate"
              size="small"
              indicator="required"
            >
              <input
                type="date"
                id="ToDate"
                name="ToDate"
                placeholder="Search everywhere"
                value={endDate ? endDate.toISOString().split("T")[0] : ""}
                onChange={handleEndDateChangeWrapper}
                min={startDate ? startDate.toISOString().split("T")[0] : ""}
                max={today}
              />
            </InputField>
          </div>
          <div className="wk-column-3">
            <InputField
              label="Manuscript Id"
              labelFor="ManuscriptId"
              size="small"
              placeholder="Please add manuscript Id"
            >
              <input
                type="text"
                placeholder="Please add manuscript Id"
                id="ManuscriptId"
                name="ManuscriptId"
                value={manuscriptId}
                autoFocus="autofocus"
                onChange={(e) => setManuscriptId(e.target.value)}
              />
            </InputField>
          </div>
        </div>

        <div className="card-sub-count">
          <ButtonField size="small">
            <button onClick={onFetchData} type="button">
              Select
            </button>
          </ButtonField>
          {showBox && (
            <ButtonField size="small" iconPosition={"left"}>
              <button type="button" onClick={exportToExcelWrapper}>
                <Iconsvg name="download-line"></Iconsvg>
                Export To Excel{" "}
              </button>
            </ButtonField>
          )}
        </div>
      </div>
      <br />
      {showBox && (
        <>
          <div className="wk-table-permit-overflow">
            <Table loading={loading} data={paymentList} columns={columns} />
          </div>
          <div className="wk-pagination">
            {pageCount > 1 && ( // Conditionally render pagination if there is more than one page
              <Pagination
                pageCount={pageCount}
                pageIndex={page}
                onOnNextArrowClick={() => setPage((prevPage) => prevPage + 1)}
                onOnPreviousArrowClick={() =>
                  setPage((prevPage) => prevPage - 1)
                }
                onOnPageClick={(page) => setPage(page)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(Payments);
