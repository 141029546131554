import React, { useState, useEffect } from "react";
import { Pagination } from "@wk/components-react16";
import { articleCitationAPI } from "../API/API";
import axios from "axios";
// eslint-disable-next-line react/prop-types
const ArticleRateTable = ({ journalId }) => {
  const [articleRates, setArticleRates] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const pageSize = 5;

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        setLoading(true);
        setArticleRates([]);
        const response = await axios.post(articleCitationAPI, {
          Id: journalId,
        });
        if (response) {
          const totalArticleRates = response.data.Data.length;
          setArticleRates(response.data.Data);
          setTotalCount(totalArticleRates);
          const calculatedPageCount = Math.ceil(totalArticleRates / pageSize);
          setPage((prevPage) => Math.min(prevPage, calculatedPageCount));
          setPageCount(calculatedPageCount);
          setLoading(false);
        } else {
          console.error("API response not successful:", response.status);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    if (journalId > 0) {
      fetchArticleData();
    }
  }, [journalId]);

  const startIdx = (page - 1) * pageSize;
  const endIdx = startIdx + pageSize;
  const articleList = articleRates.slice(startIdx, endIdx);

  return (
    <>
      <div className="wk-table-permit-overflow">
        <table className="wk-table wk-table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">ArticleType Id</th>
              <th scope="col">Article Type</th>
              <th scope="col">MS(&#8377;)</th>
              <th scope="col">MS(&#36;)</th>
              <th scope="col">APC(&#8377;)</th>
              <th scope="col">APC(&#36;)</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td align="center" colSpan={7}>
                  <p className="wk-weight-500 wk-text-muted-light">
                    Loading....
                  </p>
                </td>
              </tr>
            ) : articleList && articleList.length > 0 ? (
              articleList.map((rate, index) => (
                <tr key={index}>
                  <td>{rate.Id}</td>
                  <td>{rate.ArticleTypeId}</td>
                  <td>{rate.ArticleType}</td>
                  <td>{rate.Msinr}</td>
                  <td>{rate.Msusd}</td>
                  <td>{rate.Apcinr}</td>
                  <td>{rate.Apcusd}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td align="center" colSpan={7}>
                  <p className="wk-weight-500 wk-text-muted-light">
                    No records found!
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {pageCount > 1 && articleList && articleList.length > 0 && (
          <Pagination
            pageCount={pageCount}
            pageIndex={page}
            onOnNextArrowClick={() => setPage((prevPage) => prevPage + 1)}
            onOnPreviousArrowClick={() => setPage((prevPage) => prevPage - 1)}
            onOnPageClick={(page) => setPage(page)}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(ArticleRateTable);
