import React, { useState } from "react";
import {
  WkFooter,
  FooterContainer,
  FooterNavigation,
  FooterItem,
  FooterNavigationLink,
  FooterCopyright,
} from "@wk/components-react16";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const [dynamic] = useState({
    breakpoint: "L",
    breakpointControlMode: "controlled",
    customContainerWidth: "500px",
    mode: "workflow",
  });

  return (
    <footer className="wk-custom-footer">
      <WkFooter>
        <FooterContainer>
          <FooterNavigation>
            <FooterItem>
              <FooterNavigationLink href="https://www.wolterskluwer.com/en/privacy-cookies">
                Privacy statement{" "}
              </FooterNavigationLink>
            </FooterItem>
            <FooterItem>
              <FooterNavigationLink href="https://www.wolterskluwer.com/en/terms-of-use">
                Terms &amp; conditions{" "}
              </FooterNavigationLink>
            </FooterItem>
          </FooterNavigation>
          <FooterCopyright
            mode={dynamic.mode}
            breakpoint={dynamic.breakpoint}
            breakpointControlMode={dynamic.breakpointControlMode}
          ></FooterCopyright>
          <FooterContainer>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              {t("Cookie� Settings")}
            </button>
          </FooterContainer>
        </FooterContainer>
      </WkFooter>
    </footer>
  );
};

export default Footer;
